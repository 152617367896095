import {MapLatLng} from 'raain-ui';
import {IComparePerDate, ICompares, PositionValue, QualityPoint, RainComputationQuality, RainNode, SpeedMatrixContainer} from 'raain-model';
import {XYType} from '../xytype';
import {ProfileService} from '../profile.service';
import {FrameSet} from './FrameSet';

export interface ICompareTarget {
  date: Date;
  rainComputationId: string;
  rainComputationQualityId: string;
}

export interface IUICompare {
  name: string;
  comparePoints: XYType[];
  comparePointsMax: XYType;
  comparePointsHistory: QualityPoint[];
  remarks: string;
}

export class CompareManager {

  // public computed$: Observable<boolean>;

  public gaugesInCompare: MapLatLng[];
  public selectedGauges: MapLatLng[];
  public selectedPixels: MapLatLng[];
  public pixelsSolutions: MapLatLng[][];

  // from comparesTimeline
  public compareNames: string[];
  public uiCompares: IUICompare[];
  public compareSpeedMatrix: PositionValue[];
  public compareVersion: string;
  public compareTrustedIndicator: number;

  public currentQualityDoneDate: Date;
  public currentQualityLaunchedBy: string;
  public currentQualityTimeSpentInMs: number;
  public globalComparePoints: XYType[];
  public globalComparePointsMax: XYType;

  protected buildCompares: ICompares;
  protected selectedGauge: QualityPoint;
  protected selectedGaugeInPoints: QualityPoint;
  protected selectedGaugeInPointsDetail: string;
  protected rainComputationQualitiesPerDate: any;

  constructor(
    protected rainNode: RainNode,
    protected profileService: ProfileService,
    public onChanges: () => void = () => {
    }
  ) {
    this.cleanAll();
  }

  protected static CompareDate(date1: Date, date2: Date, minutes: number): boolean {

    const date1AsDate: Date = new Date(date1.getTime());
    const date2Diff = new Date(date2.getTime());
    date2Diff.setMinutes(date2Diff.getMinutes() + minutes);
    const diff = Math.round((date1AsDate.getTime() - date2Diff.getTime()) / (60 * 1000));
    return diff === 0;
  }

  public cleanAll() {
    this.gaugesInCompare = [];
    this.selectedGauges = [];
    this.selectedPixels = [];
    this.pixelsSolutions = [];

    this.buildCompares = {
      comparesPerDate: [],
      compareCumulative: null
    };
    this.globalComparePoints = [];
    this.globalComparePointsMax = new XYType(0, 0);
    this.rainComputationQualitiesPerDate = {};

    this.selectedGauge = null;
    this.selectedGaugeInPoints = null;
    this.cleanCompareQuality('no compare yet');
  }

  async refreshCompareQuality(frameSet: FrameSet) {

    const comparePerDate = this.getComparePerDate(frameSet.date);

    if (!comparePerDate || !comparePerDate.rainComputationQuality?.qualitySpeedMatrixContainer) {
      this.cleanCompareQuality('no current quality ' + Object.keys(this.rainComputationQualitiesPerDate));
      this.onChanges();
      return;
    }

    const uiCompares: IUICompare[] = comparePerDate.compareTimeline.map(c => {
      return {
        name: c.name,
        comparePoints: c.qualityPoints.map(p => new XYType(p.getGaugeValue(), p.getRainValue(), 2, p.gaugeLabel, p.gaugeId)),
        comparePointsMax: new XYType(c.maxValue + 20, c.maxValue + 20),
        comparePointsHistory: c.qualityPointsLegacy,
        remarks: c.remarks
      };
    });

    this.currentQualityDoneDate = comparePerDate.rainComputationQuality.isDoneDate;
    this.currentQualityTimeSpentInMs = comparePerDate.rainComputationQuality.timeSpentInMs;
    this.currentQualityLaunchedBy = comparePerDate.rainComputationQuality.launchedBy;
    this.compareVersion = comparePerDate.rainComputationQuality.getVersion();
    this.compareSpeedMatrix = comparePerDate.rainComputationQuality.qualitySpeedMatrixContainer.renderMergedMatrix({normalize: true});
    this.compareTrustedIndicator = comparePerDate.rainComputationQuality.qualitySpeedMatrixContainer.getTrustedIndicators()[0];
    this.compareNames = uiCompares.map(c => c.name);
    this.uiCompares = uiCompares;

    this.onChanges();
  }

  async refreshGlobalCompareQuality(targetsOrdered: ICompareTarget[],
                                    withCompareDuplicate: boolean) {

    try {
      await this.fetchRainComputationQualities(targetsOrdered);

      await this.buildComparesTimeline(targetsOrdered, withCompareDuplicate);

      if (!this.buildCompares.compareCumulative) {
        // throw Error('needs cumulative compare');
        return;
      }

      this.globalComparePoints = this.buildCompares.compareCumulative.qualityPoints
        .map(qp => new XYType(qp.getGaugeValue(), qp.getRainValue(), 2, qp.gaugeLabel, qp.gaugeId));
      this.globalComparePointsMax = new XYType(
        this.buildCompares.compareCumulative.maxValue + 20,
        this.buildCompares.compareCumulative.maxValue + 20);
    } catch (e) {
      console.warn(e);
    }
  }

  async setGauges() {
    // console.log('setGauges');

    const qualityPoints: QualityPoint[] = [];
    for (const compare of this.uiCompares) {
      for (const qualityPoint of compare.comparePointsHistory) {
        const alreadyAdded = qualityPoints.filter(p => p?.gaugeId === qualityPoint.gaugeId);
        if (alreadyAdded.length === 0) {
          qualityPoints.push(qualityPoint);
        }
      }
    }

    this.gaugesInCompare = qualityPoints.map(p => {
      return new MapLatLng(p.gaugeCartesianValue.lat, p.gaugeCartesianValue.lng, 0,
        p.gaugeId, p.gaugeLabel + '>' + p.getGaugeValue(),
        p.getGaugeValue());
    });

    this.resetSelectedGauges();
  }

  async selectGauge(gaugeId: string, compareIndex: number) {
    // console.log('selectGauge', gaugeId, compareIndex);

    if (gaugeId) {
      const xys = this.getCurrentQualityPoints(compareIndex).filter(p => p.gaugeId === gaugeId);
      this.selectedGauge = xys.length === 1 ? xys[0] : null;
    } else {
      this.selectedGauge = null;
    }

    if (!this.selectedGauge) {
      return this.resetSelectedGauges();
    }

    const selectedGaugeInPoints = this.getCurrentQualityPoints(compareIndex).filter(p => p.gaugeId === this.selectedGauge.gaugeId);
    let pixelsSolutions: MapLatLng[][] = [];

    if (selectedGaugeInPoints.length === 1) {
      const remarks = JSON.parse(selectedGaugeInPoints[0].remark)
        .sort((a, b) => a.criteriaAttemptedToBeMinimum - b.criteriaAttemptedToBeMinimum);
      console.log(remarks);
      pixelsSolutions = remarks
        .map(remark => remark.solutionForGauge.pixels
          .map(p => new MapLatLng(p.y + 0.005, p.x + 0.01, 0, '' + !remark.notPossible, '' + !remark.notPossible, 1)));

      const timeDeltaInMinutes = selectedGaugeInPoints[0].getTimeDeltaInMinutes();
      this.selectedGaugeInPointsDetail = '' + selectedGaugeInPoints[0].gaugeLabel + ' > '
        + Math.round(selectedGaugeInPoints[0].getGaugeValue() * 100) / 100 + ' / '
        + Math.round(selectedGaugeInPoints[0].getRainValue() * 100) / 100
        + ' dT=' + timeDeltaInMinutes;

      this.selectedGaugeInPoints = selectedGaugeInPoints[0];

    } else {
      this.selectedGaugeInPoints = null;
      this.selectedGauges = [];
      this.selectedPixels = [];
      this.pixelsSolutions = [];
      return;
    }

    this.selectedGauges = [
      new MapLatLng(this.selectedGaugeInPoints.gaugeCartesianValue.lat, this.selectedGaugeInPoints.gaugeCartesianValue.lng, undefined,
        this.selectedGaugeInPoints.gaugeId,
        this.selectedGaugeInPoints.gaugeLabel + '>' + this.selectedGaugeInPoints.gaugeCartesianValue.value,
        this.selectedGaugeInPoints.gaugeCartesianValue.value),
    ];

    this.selectGaugeInCompare();

    const pixels: MapLatLng[] = [];
    for (const rainCartesianValue of this.selectedGaugeInPoints.rainCartesianValues) {
      pixels.push(new MapLatLng(rainCartesianValue.lat + 0.005, rainCartesianValue.lng + 0.01));
    }
    this.selectedPixels = pixels;

    this.pixelsSolutions = pixelsSolutions;
  }

  protected async buildComparesTimeline(targetsOrdered: ICompareTarget[], withCompareDuplicate: boolean): Promise<ICompares> {
    const dates: Date[] = targetsOrdered.map(t => t.date);
    const qualities: RainComputationQuality[] = dates
      .map(d => this.getRainComputationQuality(d))
      .filter(d => !!d);
    this.buildCompares = SpeedMatrixContainer.BuildCompares(qualities, !withCompareDuplicate);
    return this.buildCompares;
  }

  protected getComparePerDate(date: Date): IComparePerDate {
    const existing = this.buildCompares.comparesPerDate.filter(c => c.date.getTime() === date.getTime());
    if (existing.length === 1) {
      return existing[0];
    }
    return null;
  }

  protected getCurrentQualityPoints(compareIndex: number) {
    if (compareIndex < 0 || this.compareNames.length < compareIndex) {
      return [];
    }

    return this.uiCompares[compareIndex].comparePointsHistory;
  }

  protected cleanCompareQuality(details?: string) {
    this.compareVersion = details;
    this.compareNames = [];
    this.uiCompares = [];
    this.compareSpeedMatrix = [];
    this.compareTrustedIndicator = undefined;

    this.currentQualityDoneDate = new Date(-1);
    this.currentQualityLaunchedBy = '';
    this.currentQualityTimeSpentInMs = -1;
  }

  protected async fetchRainComputationQualities(targetsOrdered: ICompareTarget[]) {
    for (const target of targetsOrdered) {
      if (!this.rainComputationQualitiesPerDate[target.date.toISOString()] && target.rainComputationQualityId) {
        try {
          this.rainComputationQualitiesPerDate[target.date.toISOString()] = await this.profileService
            .getRainCompareById(this.rainNode.getId(), target.rainComputationId, target.rainComputationQualityId);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  protected getRainComputationQuality(date: Date): RainComputationQuality {
    return this.rainComputationQualitiesPerDate[date.toISOString()];
  }

  protected selectGaugeInCompare() {
    for (const compare of this.uiCompares) {
      const comparePoints = JSON.parse(JSON.stringify(compare.comparePoints));
      comparePoints.forEach(p => {
        if (p.id === this.selectedGaugeInPoints?.gaugeId) {
          p.r = 10;
        } else {
          p.r = 3;
        }
      });
      compare.comparePoints = comparePoints;
    }
  }

  protected resetSelectedGauges() {
    // console.log('resetSelectedGauges reset');
    this.selectedGauge = null;
    this.selectedGaugeInPoints = null;
    this.selectedGauges = [];
    this.selectedPixels = [];
    this.pixelsSolutions = [];
    this.selectGaugeInCompare();
  }
}
