import {FrameSet} from './FrameSet';
import {Subject, takeUntil, timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {RainNode} from 'raain-model';
import {TimeframeContainer, TimeframeContainers} from 'raain-ui';
import {RadarService} from '../radar.service';
import {ProfileService} from '../profile.service';
import {CompareManager} from './CompareManager';

export class RefreshManager {

  public rainComputationMapDate: Date;
  public rainComputationMapDoneDate: Date;
  public rainComputationMapLaunchedBy: string;
  public rainComputationMapTimeSpentInMs: number;

  protected closeRefreshTimer: Subject<any>;
  protected countsPeriod: any;
  protected timeframesFromRadars: FrameSet[];
  protected timeframesFromRadarsExtended: FrameSet[];
  protected timeframeDates: Date[];
  protected begin: Date;
  protected end: Date;
  protected removeDuplicate: boolean;
  protected dataInternal: boolean;

  constructor(
    protected rainNode: RainNode,
    protected radarService: RadarService,
    protected profileService: ProfileService,
    protected compareManager: CompareManager,
    protected onRefreshInProgress: (countPeriods: any, timeframeDates: Date[]) => Promise<void>,
    protected onRefreshDone: (timeframeDates: Date[]) => Promise<void>,
    protected onFetchDone: (timeframeContainers: TimeframeContainers) => Promise<void>,
  ) {
    this.closeRefreshTimer = new Subject<any>();
    this.cleanAll();
  }

  public static Delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  cleanAll() {

    this.countsPeriod = {};
    this.timeframesFromRadars = [];
    this.timeframesFromRadarsExtended = [];
    this.timeframeDates = [];
    this.closeRefreshTimer?.next(null);
  }

  async fetch(dateShown: Date, withGaugesMeasures: boolean): Promise<FrameSet> {
    // set the target
    const currents = this.timeframesFromRadarsExtended.filter(t => t.date.getTime() === dateShown.getTime());
    if (currents.length !== 1) {
      console.log('no correct dateShown', dateShown, this.timeframesFromRadarsExtended);
      return;
    }

    // build [current, previous, next] targets
    const current: FrameSet = currents[0];
    const position = this.timeframesFromRadarsExtended.indexOf(current);
    let previous: FrameSet = null, next: FrameSet = null;
    const positionNames: string[] = ['' + current.date.getTime()];
    const frameSets = {};
    frameSets['' + current.date.getTime()] = current;
    if (position > 0) {
      previous = this.timeframesFromRadarsExtended[position - 1];
      positionNames.push('' + previous.date.getTime());
      frameSets['' + previous.date.getTime()] = previous;
    }
    if (position < this.timeframesFromRadarsExtended.length) {
      next = this.timeframesFromRadarsExtended[position + 1];
      if (next?.date) {
        positionNames.push('' + next.date.getTime());
        frameSets['' + next.date.getTime()] = next;
      }
    }

    // align Map with targets
    const targets: {
      date: Date,
      rainComputationId: string,
      rainComputationQualityId: string,
    }[] = [];
    for (const positionName of positionNames) {
      if (frameSets[positionName]) {
        const target = frameSets[positionName];
        target.rainComputationQualityId = this.getRainComputationQualityTarget(target);
        target.withGauge = withGaugesMeasures;
        targets.push(target);

        const timeframeContainers = await this.refreshMapTimeframeContainers(target);

        if (positionName === ('' + current.date.getTime())) {
          this.onFetchDone(timeframeContainers).then(ignored => {
          });
        }
      }
    }

    // align Compare with current date
    this.refreshCompareQuality(current).then((ignored) => {
    });

    return current;
  }

  async setPeriod(begin: Date, end: Date) {
    this.begin = begin;
    this.end = end;

    return this.refreshCounts();
  }

  async refresh(launchQualityAfter: boolean, dataInternal: boolean) {

    if (!this.begin || !this.end) {
      throw new Error('need a period');
    }

    this.dataInternal = dataInternal;
    this.compareManager.cleanAll();
    let refreshInProgress = false;
    let refreshDone = false;

    timer(1000, 15000)
      .pipe(
        switchMap(async () => {
          if (refreshInProgress) {
            return refreshDone;
          }

          refreshInProgress = true;
          this.onRefreshInProgress(await this.refreshCounts(), this.timeframeDates).then(ignored => {
          });
          refreshDone = await this.refreshPeriodInsidePolling();
          if (!refreshDone) {
            refreshInProgress = false;
          }
          return refreshDone;
        }),
        takeUntil(this.closeRefreshTimer),
      )
      .subscribe({
        next: async (refreshFinished: boolean) => {
          console.log('refreshFinished', refreshFinished, launchQualityAfter);
          if (refreshFinished) {
            this.closeRefreshTimer.next(null);
            await this.onRefreshDone(this.timeframeDates);
          }
        },
        error: (error: any) => {
          console.error('error in refresh', error);
        }
      });

  }

  async selectGauge(gaugeId: string, compareIndex: number) {
    return this.compareManager.selectGauge(gaugeId, compareIndex);
  }

  setRemoveDuplicate(b: boolean) {
    this.removeDuplicate = b;
  }

  protected async refreshCounts() {
    const counts = await this.profileService.getCounts(this.rainNode.id, this.begin, this.end, true);
    this.countsPeriod = {
      radarMeasures: counts.radarMeasures.reduce((partialSum, a) => partialSum + a.x, 0),
      gaugeMeasures: counts.gaugeMeasures.reduce((partialSum, a) => partialSum + a.x, 0),
      computationsByTeam: counts.computationsByTeam.reduce((partialSum, a) => partialSum + a.x, 0),
    };
    return this.countsPeriod;
  }

  protected getRainComputationQualityTarget(frameset: FrameSet): string {
    if (frameset.rainComputationQualities.length <= 0) {
      return undefined;
    }
    return frameset.rainComputationQualities[0].id;
  }

  protected async refreshPeriodInsidePolling(): Promise<boolean> {

    let done = false;
    try {
      done = this.refreshCheckIfDone();

      // behind refresh
      await this.refreshTimeframesFromRadars(done);
      this.refreshGlobalCompareReport(done).then(ignored => {
      });

      return done;
    } catch (e) {
      console.warn(e);
    }

    return done;
  }

  protected refreshCheckIfDone(): boolean {
    // console.log('refreshPeriodInsidePolling', this.countsPeriod.computationsByTeam, this.countsPeriod.radarMeasures)
    return this.countsPeriod.computationsByTeam === this.countsPeriod.radarMeasures;
  }

  protected async refreshTimeframesFromRadars(done: boolean) {

    if (!done) {
      return [];
    }

    const beginExtended = new Date(this.begin);
    const endExtended = new Date(this.end);
    beginExtended.setMinutes(beginExtended.getMinutes() - 7);
    endExtended.setMinutes(endExtended.getMinutes() + 7);
    this.rainNode = await this.profileService.getRainTimeframe(this.rainNode.getId(), beginExtended, endExtended, this.dataInternal);

    this.timeframesFromRadarsExtended = await this.radarService.getTimeline(this.rainNode);
    this.timeframesFromRadars = this.timeframesFromRadarsExtended
      .filter((frameSet) => this.begin.getTime() <= frameSet.date.getTime() && frameSet.date.getTime() <= this.end.getTime())
      .sort((a, b) => a.date.getTime() - b.date.getTime());

    // console.log('timeframesFromRadarsExtended:', this.timeframesFromRadarsExtended);

    this.timeframeDates = this.timeframesFromRadars.map(frameSet => frameSet.date);
    return this.timeframesFromRadars;
  }

  protected async refreshGlobalCompareReport(done: boolean) {
    if (!done) {
      return;
    }

    const targets = [];
    for (const frameSet of this.timeframesFromRadarsExtended) {
      const target = JSON.parse(JSON.stringify(frameSet));
      target.date = new Date(target.date);
      target.rainComputationQualityId = this.getRainComputationQualityTarget(frameSet);
      targets.push(target);
    }
    await this.compareManager.refreshGlobalCompareQuality(targets, !this.removeDuplicate);
  }

  protected async refreshMapTimeframeContainers(target: {
    date: Date,
    rainComputationId: string,
    withGauge: boolean
  }) {
    if (!target || !target.rainComputationId) {
      console.warn('Pb with refreshMapTimeframeContainers insights');
      return;
    }

    const rainNode = this.rainNode;
    const containers: TimeframeContainer[] = [];
    const newCreatedTimeframeContainers = new TimeframeContainers(containers);
    try {
      const rainNodeCartesianMap = await this.profileService
        .getRainComputationCartesianMapById(rainNode.getId(), target.rainComputationId);
      if (rainNodeCartesianMap) {
        newCreatedTimeframeContainers.addFromRainComputationMap(rainNodeCartesianMap, false);

        this.rainComputationMapDate = rainNodeCartesianMap.date;
        this.rainComputationMapDoneDate = rainNodeCartesianMap.isDoneDate;
        this.rainComputationMapLaunchedBy = rainNodeCartesianMap.launchedBy;
        this.rainComputationMapTimeSpentInMs = rainNodeCartesianMap.timeSpentInMs;
      }
    } catch (e) {
      console.warn('Pb with computations for id:', target.rainComputationId, e);
    }

    return newCreatedTimeframeContainers;
  }

  protected async refreshCompareQuality(frameSet: FrameSet) {
    await this.compareManager.refreshCompareQuality(frameSet);
    await this.compareManager.setGauges();
  }
}
