import {Pipe, PipeTransform} from '@angular/core';
import {EventNode, RainNode} from 'raain-model';
import {CONSTANTS} from './shared.const';
import {FrameSet} from './tools';

@Pipe({name: 'areStopped'})
export class AreStopped implements PipeTransform {
  transform(rains: RainNode[]): RainNode[] {
    return rains.filter((rain) => (rain.status <= CONSTANTS._RAIN_STATUS_0_NOT_STARTED));
  }
}

@Pipe({name: 'areInProgress'})
export class AreInProgressPipe implements PipeTransform {
  transform(rains: RainNode[]): RainNode[] {
    return rains.filter((rain) => (
      CONSTANTS._RAIN_STATUS_0_NOT_STARTED < rain.status && rain.status < CONSTANTS._RAIN_STATUS_1_READY_TO_COMPUTE
    ));
  }
}

@Pipe({name: 'areReady'})
export class AreReady implements PipeTransform {
  transform(rains: RainNode[]): RainNode[] {
    return rains.filter((rain) => (
      rain.status >= CONSTANTS._RAIN_STATUS_1_READY_TO_COMPUTE && rain.quality > CONSTANTS._INPUT_VALIDITY_NOT_CONFIDENT
    ));
  }
}

@Pipe({name: 'isReady'})
export class IsReady implements PipeTransform {
  transform(rain: RainNode): RainNode {
    if (rain.status >= CONSTANTS._RAIN_STATUS_1_READY_TO_COMPUTE) {
      return rain;
    }
    return null;
  }
}

@Pipe({name: 'isNotReady'})
export class IsNotReady implements PipeTransform {
  transform(rain: RainNode): RainNode {
    if (rain.status < CONSTANTS._RAIN_STATUS_1_READY_TO_COMPUTE) {
      return rain;
    }
    return null;
  }
}

@Pipe({name: 'waitForValidation'})
export class WaitForValidation implements PipeTransform {
  transform(rain: RainNode): RainNode {
    if (rain.status === CONSTANTS._RAIN_STATUS_1_READY_TO_COMPUTE) {
      return rain;
    }
    return null;
  }
}

@Pipe({name: 'hasGoodQuality'})
export class HasGoodQuality implements PipeTransform {
  transform(rain: RainNode): RainNode {
    if (rain.quality > CONSTANTS._INPUT_VALIDITY_NOT_CONFIDENT) {
      return rain;
    }
    return null;
  }
}

@Pipe({name: 'hasNotGoodQuality'})
export class HasNotGoodQuality implements PipeTransform {
  transform(rain: RainNode): RainNode {
    if (rain.quality < CONSTANTS._INPUT_VALIDITY_VALID) {
      return rain;
    }
    return null;
  }
}

@Pipe({name: 'progressValue'})
export class ProgressValue implements PipeTransform {
  transform(rain: RainNode): number {
    if (rain.status < 0) {
      return 0;
    }

    // const value = (rain.progressIngest + rain.progressComputing) / 2;
    const value = rain.status / 2; // 0
    // if (rain.status === CONSTANTS._RAIN_STATUS_0_READY_TO_BUILD) {
    //   value = 0.3;
    // } else if (rain.status === CONSTANTS._RAIN_STATUS_1_BUILDING) {
    //   value = 0.6;
    // } else if (rain.status === CONSTANTS._RAIN_STATUS_1_READY_TO_COMPUTE) {
    //   value = 1;
    // }
    return value;
  }
}

@Pipe({name: 'progressBuffer'})
export class ProgressBuffer implements PipeTransform {
  transform(rain: RainNode): number {
    if (rain.quality < 0) {
      return 0;
    }

    const value = rain.quality / 2;
    return value;
  }
}

@Pipe({name: 'timeframeComputationProgress'})
export class TimeframeComputationProgress implements PipeTransform {
  transform(frameSets: FrameSet[]): number {
    let progress = 0;
    if (!frameSets || frameSets.length === 0) {
      return 1;
    }

    for (const frameset of frameSets) {
      if (frameset.radarMeasureIds.length && frameset.rainComputationId) {
        progress++;
      }
    }

    return progress / frameSets.length;
  }
}

@Pipe({name: 'timeframeQualityProgress'})
export class TimeframeQualityProgress implements PipeTransform {
  transform(frameSets: FrameSet[]): number {
    let progress = 0;
    if (!frameSets || frameSets.length === 0) {
      return 1;
    }

    for (const frameset of frameSets) {
      const qualities = frameset.rainComputationQualities ? frameset.rainComputationQualities : [];
      const atLeastOneIsNotOutdated = qualities
        .filter(q => q.version.indexOf('outdated') < 0);
      if (frameset.radarMeasureIds.length && frameset.rainComputationId && atLeastOneIsNotOutdated.length) {
        progress++;
      }
    }

    return progress / frameSets.length;
  }
}

@Pipe({
  name: 'haveNotBeenRed',
  pure: false
})
export class HaveNotBeenRed implements PipeTransform {
  transform(events: EventNode[]): EventNode[] {
    const haveNotBeenRed = events.filter((event) => (!event.red));
    return haveNotBeenRed;
  }
}
