<div id="container">
  <div id="canvas-speed-matrix">
    <div [hidden]="hide">
      <canvas #canvasSpeedElement id="indic1"></canvas>
    </div>
    <div [hidden]="!hide">
      <span id="hiddenSquare"> &nbsp;</span>
    </div>
    <!--  <span>compareTrustedIndicator {{ compareTrustedIndicator }}</span> -->
  </div>
</div>
