import {Component} from '@angular/core';
import {Platform} from '@ionic/angular';
import {SplashScreen} from '@capacitor/splash-screen';
import {FidjService, LoggerLevelEnum} from 'fidj';
import {ProfileService} from './shared';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
import {EventNode} from 'raain-model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    {
      title: 'Dashboard',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Radars',
      url: '/radars',
      icon: 'wifi'
    },
    {
      title: 'Rains',
      url: '/rains',
      icon: 'rainy'
    },
    {
      title: 'Events',
      url: '/events',
      icon: 'notifications-outline'
    },
    {
      title: 'Settings',
      url: '/profile',
      icon: 'settings'
    },
    {
      title: 'Sign out',
      url: '/login',
      icon: 'log-out'
    }
  ];

  private notifications: EventNode[];

  constructor(
    private platform: Platform,
    private fidjAngularService: FidjService,
    private router: Router,
    private profile: ProfileService
  ) {
    this.notifications = [];
    this.initializeApp();
  }

  initializeApp() {
    this.profile.defaultUrlForAPI = environment.serverUrl;
    this.platform.ready().then(async () => {
      // this.statusBar.styleDefault();
      await this.initFidj();
      await this.getNotifications();
      await SplashScreen.hide();
    });
  }

  async initFidj() {

    try {
      await this.fidjAngularService.init(environment.fidjKey,
        {
          logLevel: LoggerLevelEnum.INFO,
          crypto: false,
          prod: environment.production,
          useDB: false,
        });

      console.log('initFidj done.');
      this.profile.readyForSync.next(true);
      if (!this.fidjAngularService.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
    } catch (err) {
      if (err.code !== 404) {
        console.error('initFidj catch pb: ', err);
        // this.profile.readyForSync.next(false);
      } else {
        console.warn('initFidj - fidj is not online => demo only ', err);
        // TODO use observable to ?? : this.profile.readyForSync.next(true);
        if (!this.fidjAngularService.isLoggedIn()) {
          this.router.navigate(['/login']);
        }
      }

    }
  }

  async getNotifications() {
    this.notifications = await this.profile.getAllNotifications();
  }

  getBadges(page: { icon: string; title: string; url: string }) {
    if (page.title !== 'Events') {
      return 0;
    }

    return this.notifications?.length;
  }
}
