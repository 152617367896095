import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ElementsFactory, SpeedMatrixElementInput} from 'raain-ui';
import {PositionValue} from 'raain-model';

@Component({
  selector: 'raain-speed',
  templateUrl: './raain-speed.component.html',
  styleUrls: ['./raain-speed.component.scss'],
})
export class RaainSpeedComponent implements AfterViewChecked, OnDestroy, OnChanges {

  @Input()
  public compareSpeedMatrix: PositionValue[];

  @Input()
  public compareTrustedIndicator: number;

  @Input()
  public currentHeight: number;
  public currentWidth: number;
  public hide = false;
  @ViewChild('canvasSpeedElement')
  protected canvasSpeedElement: ElementRef;
  private onSizeChanged;
  private created;

  constructor(private ngZone: NgZone) {
    this.delete();
    this.onSizeChanged = () => {
    };
  }

  public ngAfterViewChecked(): void {
    this.create();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.change(changes);
  }

  public ngOnDestroy(): void {
    this.delete();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any): void {
    this.updateMapSize();
  }

  private create() {
    if (this.created || !this.compareSpeedMatrix?.length) {
      return;
    }
    this.created = true;

    // this.ngZone.runOutsideAngular(() => {
    setTimeout(() => {
      const factory = new ElementsFactory();
      factory.createSpeedMatrixIndicator(this.canvasSpeedElement.nativeElement,
        new SpeedMatrixElementInput(this.compareSpeedMatrix, this.compareTrustedIndicator));
    }, 10);
    //   });

  }

  private delete() {
    this.created = false;
    this.hide = false;
  }

  private change(changes: SimpleChanges) {
    if (changes.compareSpeedMatrix) {
      if (!this.compareSpeedMatrix?.length) {
        this.hide = true;
        return;
      }
    }

    setTimeout(() => {
      this.delete();
      this.create();
    }, 10);
  }

  private updateMapSize(): void {

    const mapDivWidth = this.canvasSpeedElement.nativeElement.parentNode?.parentNode['clientWidth'];

    const currentWidth = mapDivWidth ||
      (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    const currentHeight = this.currentHeight ||
      (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    this.currentWidth = currentWidth;
    this.currentHeight = currentHeight;

    this.ngZone.run(() => {
      this.onSizeChanged();
    });
  }
}
