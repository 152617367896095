import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CompareElement, CompareElementInput, ElementsFactory} from 'raain-ui';
import {XYType} from '../xytype';

@Component({
  selector: 'raain-compare',
  templateUrl: './raain-compare.component.html',
  styleUrls: ['./raain-compare.component.scss'],
})
export class RaainCompareComponent implements AfterViewChecked, OnDestroy, OnChanges {

  @Input()
  public points: Array<XYType>;

  @Input()
  public pointMax: XYType;

  @Input()
  public remarks: string;

  @Input()
  public compareIndex: number;

  @Input()
  public currentHeight: number;

  @Input()
  public currentWidth: number;

  @Output()
  public selectedPoint = new EventEmitter<{ point: XYType, compareIndex: number }>();

  @ViewChild('canvasElement')
  protected canvasElement: ElementRef;

  private compareElement: CompareElement;
  private onSizeChanged;
  private created;

  constructor(private ngZone: NgZone) {
    this.onSizeChanged = () => {
    };
  }

  public ngAfterViewChecked(): void {
    this.create();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.change(changes);
  }

  public ngOnDestroy(): void {
    this.delete();
  }

  protected onClick(point: XYType): void {
    this.selectedPoint.emit({point, compareIndex: this.compareIndex});
  }

  protected changeData(newData: XYType[], newMaxLine?: XYType[]) {

    let changed = false;
    const dataset = this.compareElement?.chart?.data?.datasets[0];
    if (dataset) {
      dataset.data = newData;
      changed = true;
    }

    const line = this.compareElement?.chart?.data?.datasets[1];
    if (line) {
      line.data = newMaxLine;
      changed = true;
    }

    if (changed) {
      this.compareElement?.chart?.update();
    }
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any): void {
    this.updateMapSize();
  }

  private create() {
    if (this.created) {
      return;
    }
    this.created = true;

    const factory = new ElementsFactory();
    this.compareElement = factory.createCompare(this.canvasElement.nativeElement,
      new CompareElementInput(this.points.map(p => {
          return {x: p.x, y: p.y, r: p.r, name: p.name, id: p.id};
        }),
        this.pointMax, this.onClick.bind(this)));
  }

  private delete() {
    if (this.compareElement) {
      this.compareElement?.chart?.destroy();
    }
    this.created = false;
  }

  private change(changes: SimpleChanges) {
    if (changes.points) {
      this.changeData(this.points, [{x: 0, y: 0}, this.pointMax]);
    }
  }

  private updateMapSize(): void {

    const mapDivWidth = this.canvasElement.nativeElement.parentNode?.parentNode['clientWidth'];
    const padding = 20;
    let currentWidth = mapDivWidth ||
      (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    const currentHeight = this.currentHeight ||
      (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    currentWidth -= padding;
    this.currentWidth = currentWidth;
    this.currentHeight = currentHeight;

    this.ngZone.run(() => {
      this.onSizeChanged();
    });
  }

}
