<ion-grid>
  <ion-row *ngIf="cumulative">
    <ion-col size="12">
      <ion-card class="card-compare">
        <ion-card-header>Cumulative</ion-card-header>
        <ion-card-content>
          <raain-compare
            (selectedPoint)="onClick($event)"
            [compareIndex]="0"
            [currentHeight]="500"
            [pointMax]="compareManager.globalComparePointsMax"
            [points]="compareManager.globalComparePoints"></raain-compare>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="!cumulative">

    <!--
    <ion-col>
      <ion-card id="card-matrix">
        <div *ngIf="compareManager?.compareNames?.length">
          <raain-speed [compareSpeedMatrix]="compareManager.compareSpeedMatrix"
                       [compareTrustedIndicator]="compareManager.compareTrustedIndicator"
                       [currentHeight]="300"
          ></raain-speed>
        </div>
        <ion-card-content>
          {{ compareManager?.compareVersion }}
        </ion-card-content>
      </ion-card>
    </ion-col> -->

    <ion-col *ngFor="let compare of  compareManager?.uiCompares; index as compareIndex" size="12" size-md="6">
      <ion-card class="card-compare">
        <ion-card-header>{{ compare.name }} {{ compare.remarks }}
        </ion-card-header>
        <ion-card-content *ngIf="compare.comparePoints?.length">
          <raain-compare
            (selectedPoint)="onClick($event)"
            [compareIndex]="compareIndex"
            [currentHeight]="500"
            [pointMax]="compare.comparePointsMax"
            [points]="compare.comparePoints"></raain-compare>
        </ion-card-content>
      </ion-card>
    </ion-col>

    <!--
    <ion-card  id="card-selected-gauge" slot="end">
      <ion-card-content>
        <ion-accordion-group>

          <ion-accordion value="selected">
            <ion-item color="light" slot="header">
              <ion-label>debug</ion-label>
            </ion-item>

            <div class="ion-padding" slot="content">
              date: {{ currentTimeframeTarget.date |  date:'yyyy-MM-dd HH:mm:ss' }}<br>
              radarMeasureId: {{ currentTimeframeTarget.radarMeasureId }}<br>
              rainComputationId: {{ currentTimeframeTarget.rainComputationId }}<br>
              rainComputationVersion: {{ currentTimeframeTarget.rainComputationVersion }}<br>
              rainComputationQualitiesPerDate :
              <ul>
                <li *ngFor="let rcq of currentTimeframeTarget.rainComputationQualitiesPerDate">
                  {{ rcq.version }} - {{ rcq.id }}
                </li>
              </ul>
            </div>

          </ion-accordion>
          <ion-accordion *ngIf="selectedGauge" value="context">
            <ion-item color="light" slot="header">
              <ion-label>{{ selectedGaugeInPointsDetail }}</ion-label>
            </ion-item>
            <div [innerHTML]="formatLabelOfSelectedGauge(selectedGaugeInPoints)" class="ion-padding"
                 slot="content"></div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card-content>
    </ion-card>
    -->
  </ion-row>
</ion-grid>
