<ion-grid>
  <ion-row>
    <ion-col id="col-container">
      <div [style.height.px]="currentHeight" [style.width.px]="currentWidth" id="canvas-container">
        <canvas #canvasElement></canvas>
      </div>
      <ion-button (click)="reset(2)" size="small">reset 2</ion-button>
      <ion-button (click)="reset(4)" size="small">reset 4</ion-button>
      <ion-button (click)="reset(6)" size="small">reset 6</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
