import {FidjService} from 'fidj-angular';
import {EventNode, GaugeNode, Link, PeopleNode, RadarNode, RainNode} from 'raain-model';
import {Storage} from './storage.service';

export class FidjStorageNode {

  public radars: RadarNode[] = [];
  public rains: RainNode[] = [];
  public gauges: GaugeNode[] = [];
  public team: PeopleNode;
  public events: EventNode[] = [];

  public infos: any = {};

  static getEmptyNode() {
    return new FidjStorageNode();
  }

  static getDemoNode() {
    const demoNode = new FidjStorageNode();
    const link = new Link('rain', 'https://demo/api/rains/2');
    /*
    demoNode.radars = [
      new RadarNode({
        id: '5efd04569cb1f4161bd69dc7',
        name: 'demo radar A',
        links: [link],
        latitude: 48.774569,
        longitude: 2.008407
      }),
      new RadarNode({
        id: 'dr2',
        name: 'demo radar B',
        links: [link],
        latitude: 0.11,
        longitude: -0.753
      }),
      new RadarNode({
        id: 'dr3',
        name: 'demo radar C',
        latitude: 0.13,
        longitude: -0.753,
        links: []
      }),
      new RadarNode({
        id: 'dr4',
        name: 'demo radar D',
        latitude: 0.14,
        longitude: -0.74,
        links: []
      })];
    demoNode.rains = [
      new RainNode({
        id: '5efd04569cb1f4161bd69dc8',
        name: 'Demo rain zone A',
        links: [new Link('radar', 'https://demo/api/radars/5efcfe619cb1f4161bd69dc3')],
        status: 0,
        quality: 75,
        latitude: 48.774569,
        longitude: 2.008407
      }),
      new RainNode({
        id: 'dz2',
        name: 'Demo rain zone B',
        radars: [demoNode.radars[0], demoNode.radars[1]],
        status: 1,
        quality: 50,
        latitude: 48.774569,
        longitude: 2.008407
      }),
      new RainNode({
        id: 'dz3',
        name: 'Demo rain zone C',
        radars: [demoNode.radars[0], demoNode.radars[1]],
        status: 2,
        quality: 75,
        latitude: 48.774569,
        longitude: 2.008407
      }),
      new RainNode({
        id: 'dz4',
        name: 'Demo rain zone D',
        radars: [demoNode.radars[0], demoNode.radars[1]],
        status: 3,
        quality: 95,
        latitude: 48.774569,
        longitude: 2.008407
      })];

    demoNode.gauges = [
      new GaugeNode({
        id: 'g1',
        name: 'Gauge A',
        latitude: 48.7748,
        longitude: 2.28407,
      }),
      new GaugeNode({
        id: 'g2',
        name: 'Gauge B',
        latitude: 48.874569,
        longitude: 2.108407,
      })];
    demoNode.events = [{
      id: 'e2',
      title: 'Need support ?',
      status: 0,
      red: false,
      description: 'This area is dedicated to support you and your team. Support is made on Radar or Rain quality, ' +
        'or any feedback we can have about your production system. Our goal : improving your data.',
      created: new Date(),
      modified: new Date()
    }];
    demoNode.team = {
      id: 'p1',
      email: 'demo@demo.com',
      name: 'demo guy',
      description: 'the demo guy'
    };

     */
    return demoNode;
  }
}

class FidjStorageResult {
  data: FidjStorageNode;
}

export class FidjStorage {

  private isDemoMode: boolean;
  private node: FidjStorageNode = FidjStorageNode.getEmptyNode();
  private fidjMetaResult: FidjStorageResult = {data: new FidjStorageNode()};

  constructor(private storage: Storage) {
  }

  public async storeData(fidjAngularService: FidjService, data) {
    this.node = JSON.parse(JSON.stringify(data));
    this.fidjMetaResult.data = this.node;

    if (this.isDemoMode) {
      this.storage.set('fidjMetaResult', JSON.stringify(this.fidjMetaResult));
      return;
    }

    await fidjAngularService.put(this.fidjMetaResult);
  }

  public async getRefreshedNodeCopy(fidjAngularService: FidjService): Promise<FidjStorageNode> {

    if (this.isDemoMode) {
      const fidjMetaResult = this.storage.get('fidjMetaResult');
      if (fidjMetaResult) {
        this.fidjMetaResult = JSON.parse(fidjMetaResult);
        this.node = this.fidjMetaResult.data;
      }
      return JSON.parse(JSON.stringify(this.node));
    }

    const firstDemoData = async () => {
      this.node = FidjStorageNode.getDemoNode();
      await this.storeData(fidjAngularService, this.node);
    };

    await fidjAngularService.sync(firstDemoData);
    const fidjFindAllResults: any = await fidjAngularService.findAll();

    if (fidjFindAllResults && fidjFindAllResults.length > 0) {
      if (fidjFindAllResults[0].data) {
        this.fidjMetaResult = fidjFindAllResults[0];
        this.node = this.fidjMetaResult.data;
      }
    }

    return JSON.parse(JSON.stringify(this.node));
  }

  setDemoMode(isDemo: boolean) {
    this.isDemoMode = isDemo;
  }
}
