<ion-app>
  <ion-split-pane contentId="main-content" when="(min-width: 5000px)">
    <ion-menu contentId="main-content" type="overlay">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content id="my-content">
        <ion-list>
          <ion-menu-toggle *ngFor="let p of appPages" auto-hide="false">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
              <ion-icon [name]="p.icon" slot="start"></ion-icon>
              <ion-label>
                {{ p.title }}
              </ion-label>
              <ion-badge *ngIf="getBadges(p)" slot="end">{{ getBadges(p) }}</ion-badge>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
