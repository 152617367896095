<ion-grid>
  <ion-row>
    <ion-col id="col-container">
      <div id="canvas-container">
        <h1 *ngIf="remarks">{{ remarks }}</h1>

        <!-- [style.height.px]="currentHeight" [style.width.px]="currentWidth" [width]="currentWidth" [height]="currentHeight"-->
        <canvas #canvasElement></canvas>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
