import {Injectable} from '@angular/core';
import {MeasureValuePolarContainer, RadarPolarMeasureValue, RainNode, RainPolarMeasureValue} from 'raain-model';
import {PolarMapValue} from 'raain-ui';
import {ProfileService} from './profile.service';
import {FrameSet} from './tools';

@Injectable()
export class RadarService {


  constructor(
    private profileService: ProfileService,
  ) {
  }

  static getGeoValuesFromRainMeasures(measureValue: RainPolarMeasureValue, rain: RainNode): PolarMapValue[] {
    return this.computeValues(measureValue.getPolars(), rain);
  }

  static getGeoValuesFromRadarMeasures(measureValue: RadarPolarMeasureValue, rain: RainNode): PolarMapValue[] {
    return this.computeValues(measureValue.getPolars(), rain);
  }

  private static computeValues(polarMeasureValues: MeasureValuePolarContainer[], rain: RainNode) {
    const values: PolarMapValue[] = [];
    polarMeasureValues.forEach(p => {
      const azimuth = p.azimuth;
      let distance = 0;
      const polarEdges = p.polarEdges;
      polarEdges.forEach(e => {
        const pmv = new PolarMapValue(e, azimuth, distance);

        // TODO rainNode.latLngRectsAsJSON
        //  pmv.setCenter(rain);

        values.push(pmv);
        distance += p.distance;
      });
    });

    return values;
  }

  async getTimeline(rainNode: RainNode): Promise<FrameSet[]> {
    const timeline: FrameSet[] = [];
    const links = rainNode.getLinks();
    const rainId = rainNode.getId();

    // dates and radar measures
    for (const link of links) {
      const measureIndex = link.href.indexOf('measures/');
      if (measureIndex >= 0) {
        const start = measureIndex + 9;
        const str1 = link.href.substring(start);
        if (str1) {
          const end = str1.indexOf('/');
          const dateStr = str1.substring(0, end);
          const idStr = str1.substring(end + 1);

          const frameDate = new Date(dateStr);
          const existingTargets = timeline.filter(t => t.date.getTime() === frameDate.getTime());
          if (existingTargets.length) {
            existingTargets[0].radarMeasureIds.push(idStr);
          } else {
            timeline.push(new FrameSet(frameDate, [idStr]));
          }
        }
      }
    }

    // rain computations
    for (const link of links) {
      const computationIndex = link.href.indexOf('computations/');
      if (computationIndex >= 0) {
        const start = computationIndex + 13;
        const str1 = link.href.substring(start);
        if (str1) {
          const splitted = str1.split('/');
          const date = new Date(splitted[0]);
          const version = splitted[1];
          const rainComputationId = splitted[2];
          const existingTargets = timeline.filter(t => t.date.getTime() === date.getTime());
          for (const target of existingTargets) {
            target.rainComputationId = rainComputationId;
            target.rainComputationVersion = version;
            target.rainComputationQualities = await this.getBuilds(rainId, rainComputationId);
          }
        }
      }
    }

    return timeline;
  }

  async getBuilds(rainId: string, rainComputationId: string): Promise<{ id: string, version: string, }[]> {
    return await this.profileService.getRainCompares(rainId, rainComputationId);
  }
}
