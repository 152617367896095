import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {FidjService} from 'fidj';
import {ProfileService} from '../shared';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private fidjAngularService: FidjService,
              private profile: ProfileService) {

  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    if (this.profile.isDemoMode) {
      return true;
    }

    const loggedIn = this.fidjAngularService.isLoggedIn();
    const isAdmin = (await this.fidjAngularService.getRoles()).indexOf('admin') >= 0;
    return loggedIn && isAdmin;
  }

}
