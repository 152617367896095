import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ConfigurationElement, ConfigurationElementInput, ElementsFactory} from 'raain-ui';
import {XYType} from '../xytype';

@Component({
  selector: 'raain-configuration',
  templateUrl: './raain-configuration.component.html',
  styleUrls: ['./raain-configuration.component.scss'],
})
export class RaainConfigurationComponent implements AfterViewChecked, OnDestroy, OnChanges {

  @Input()
  public points: XYType[];

  @Output()
  public changedPoints = new EventEmitter<XYType[]>();

  @Input()
  public currentHeight: number;

  @Input()
  public currentWidth: number;

  @Input()
  public minPoint: XYType = {x: 0, y: 0};

  @Input()
  public maxPoint: XYType = {x: 300, y: 100};

  @ViewChild('canvasElement')
  protected canvasElement: ElementRef;

  private configurationElement: ConfigurationElement;
  private onDrag;
  private onSizeChanged;
  private created;

  constructor(private ngZone: NgZone) {
    this.onSizeChanged = () => {
    };
    this.onDrag = (e: any) => {
      this.changedPoints.emit(this.points);
    }
  }

  public ngAfterViewChecked(): void {
    this.create();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.change(changes);
  }

  public ngOnDestroy(): void {
    this.delete();
  }

  async reset(count = 4) {
    const points = [];
    for (let c = 0; c < count; c++) {
      points.push(new XYType((c + 1) * 10, (c + 1) * 10));
    }
    this.points = points;

    await this.delete();
    this.create();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any): void {
    // this.updateMapSize();
  }

  private create() {
    if (this.created) {
      return;
    }
    this.created = true;

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const factory = new ElementsFactory();
        this.configurationElement = factory.createConfiguration(this.canvasElement.nativeElement,
          new ConfigurationElementInput(this.points,
            this.minPoint,
            this.maxPoint,
            this.onDrag));
      }, 10);
    });

  }

  private async delete() {
    await this.configurationElement?.chart?.destroy();
    this.created = false;
  }

  private async change(changes: SimpleChanges) {
    // console.log('changed', changes);
    await this.delete();
    this.create();
  }

  private updateMapSize(): void {

    const mapDivWidth = this.canvasElement.nativeElement.parentNode?.parentNode['clientWidth'];
    const padding = 20;
    let currentWidth = mapDivWidth ||
      (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    const currentHeight = this.currentHeight ||
      (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    currentWidth -= padding;
    this.currentWidth = currentWidth;
    this.currentHeight = currentHeight;

    this.ngZone.run(() => {
      this.onSizeChanged();
    });
  }
}
