// TODO remove constants but preferred progress indicator [0 to 1]
export const CONSTANTS = {

  _RAIN_STATUS_0_NOT_STARTED: 0,
  _RAIN_STATUS_0_WAIT_FOR_ECHOES: 0.1,
  _RAIN_STATUS_0_WAIT_FOR_INPUT: 0.2,
  _RAIN_STATUS_0_WAIT_FOR_INTERNAL_VALIDATION: 0.3,
  _RAIN_STATUS_0_READY_TO_BUILD: 0.4,
  _RAIN_STATUS_1_BUILDING: 0.5,
  _RAIN_STATUS_1_READY_TO_COMPUTE: 0.6,

  _RAIN_COMPUTATION_IS_READY: 0,
  _RAIN_COMPUTATION_IN_PROGRESS: 0.5,
  _RAIN_COMPUTATION_DONE: 1,

  _INPUT_VALIDITY_NOT_CHECKED_YET: -1,
  _INPUT_VALIDITY_CHECK_IN_PROGRESS: -0.5,
  _INPUT_VALIDITY_NOT_VALID: 0,
  _INPUT_VALIDITY_NOT_CONFIDENT: 0.5,
  _INPUT_VALIDITY_VALID: 1,

  _VERIFY_STATUS_FIND_RADARS: 0.3,
  _VERIFY_STATUS_FIND_SIMILAR: 0.6,
  _VERIFY_STATUS_FIND_GAUGES: 0.7,

  _COMPUTATION_STATUS_UNKNOWN: 0,
  _COMPUTATION_STATUS_IN_PROGRESS: 0.4,
  _COMPUTATION_STATUS_FINISHED: 1,

};
