import {Injectable} from '@angular/core';
import {LocalStorage} from 'fidj-angular';

@Injectable()
export class Storage extends LocalStorage {

  constructor() {
    super('raain-app.');
  }
}
