import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {
  EventNode,
  GaugeMeasure,
  GaugeNode,
  RadarNode,
  RainComputation,
  RainComputationMap,
  RainComputationQuality,
  RainNode,
  SpeedMatrixContainer,
  TeamNode
} from 'raain-model';

import {Storage} from './storage.service';

import {FidjStorage, FidjStorageNode} from './fidj-storage.model';
import {ErrorInterface, FidjService, LoggerLevelEnum} from 'fidj-angular';
import {XYType} from './xytype';
import {GaugeNodeFilter} from './tools';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public readyForSync: BehaviorSubject<boolean>;
  public asTeamId: string;

  protected email: string;
  protected isDemo: boolean;
  protected roles: Array<string>;
  protected nodeData: FidjStorageNode;
  protected fidjStorage: FidjStorage;
  protected haveTryToReconnect: boolean;

  constructor(protected storage: Storage,
              protected fidjAngularService: FidjService,
              protected http: HttpClient,
              private router: Router,) {

    console.log('ProfileService: New####');

    this.email = this.storage.get('email') || null;
    this.asTeamId = this.storage.get('asTeamId') || null;
    this.readyForSync = new BehaviorSubject<boolean>(false);
    this.roles = [];
    this.fidjStorage = new FidjStorage(storage);
    this.isDemoMode = false;
  }

  get isDemoMode() {
    return this.isDemo;
  }

  set isDemoMode(mode) {
    this.isDemo = mode ? mode : true;
    this.fidjStorage.setDemoMode(this.isDemo);
  }

  get defaultUrlForAPI() {
    return this.storage.get('UrlForAPI');
  }

  set defaultUrlForAPI(url: string) {
    this.storage.set('UrlForAPI', url);
  }

  async refreshProfile(): Promise<FidjStorageNode> {
    try {
      this.nodeData = await this.fidjStorage.getRefreshedNodeCopy(this.fidjAngularService);
      this.setRoles(await this.fidjAngularService.getRoles());
      return this.nodeData;
    } catch (e) {
      await this.checkError(e);
    }
  }

  getEmail() {
    return this.email;
  }

  setEmail(email: string) {
    this.email = email;
    this.storage.set('email', this.email);
  }

  async logout(fidjKey: string, fidjProd: boolean) {

    this.storage.remove('email');
    await this.fidjAngularService.logout(true);

    try {
      await this.fidjAngularService.init(fidjKey,
        {
          logLevel: LoggerLevelEnum.INFO,
          crypto: false,
          prod: fidjProd,
          useDB: false,
        });

      console.log('initFidj done.');
      this.readyForSync.next(true);
    } catch (err) {
      console.error('initFidj catch pb: ', err);
    }

  }

  async checkError(error: ErrorInterface) {
    console.warn('checkError', error);
    if (error.code === 401) {
      console.log('looks not logged in');

      if (this.router.url.indexOf('login') < 0) {
        try {
          const sync = await this.fidjAngularService.sync();
        } catch (e) {
          return this.gotoLout();
        }
      }
    }
  }

  async gotoLout() {
    if (this.router.url.indexOf('login') > -1) {
      return;
    }
    await this.router.navigateByUrl('/logout', {skipLocationChange: true});
    // await this.router.navigate(['/logout']);
    // await this.router.navigate(['/login']);
  }

  async gotoLogin() {
    if (this.router.url.indexOf('login') > -1) {
      return;
    }
    // await this.router.navigateByUrl('/', {skipLocationChange: true});
    await this.router.navigate(['/login']);
  }

  isLoggedIn() {
    return this.fidjAngularService.isLoggedIn();
  }

  async storeAll() {
    return this.fidjStorage.storeData(this.fidjAngularService, this.nodeData);
  }

  isAdmin(): boolean {
    return this.roles.indexOf('admin') > -1;
  }

  // === Notifications ===

  async createNotification(rainId: string, message: string) {
    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'notifications',
        verb: 'POST',
        defaultKeyUrl: this.defaultUrlForAPI + '/notifications',
        data: {
          rain: rainId,
          message,
        },
      });
      return new EventNode(resp.data);
    } catch (e) {
      console.error('createNotification error:', e);
    }

    return null;
  }

  async getNotifications(rainId: string) {
    try {
      const args = '?rain=' + rainId;
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'notifications',
        verb: 'GET',
        relativePath: args,
        defaultKeyUrl: this.defaultUrlForAPI + '/notifications',
      });

      return resp.data.notifications.map(n => new EventNode(n));
    } catch (e) {
      console.error('createNotification error:', e);
    }

    return null;
  }

  async getAllNotifications(): Promise<EventNode[]> {
    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'notifications',
        verb: 'GET',
        defaultKeyUrl: this.defaultUrlForAPI + '/notifications',
      });

      return resp.data.notifications.map(n => new EventNode(n));
    } catch (e) {
      await this.checkError(e);
    }

    return [];
  }

  // === Teams ===

  async getTeams(): Promise<Array<TeamNode>> {
    const teams = [];
    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'teams_all',
        verb: 'GET',
        defaultKeyUrl: this.defaultUrlForAPI + '/teams_all'
      });
      for (const team of resp.data.teams) {
        teams.push(new TeamNode(team));
      }
    } catch (e) {
      console.error('getTeams error:', e);
    }
    return teams;
  }

  async getTeam(teamId: string): Promise<TeamNode> {
    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'teams',
        verb: 'GET',
        relativePath: teamId,
        defaultKeyUrl: this.defaultUrlForAPI + '/teams'
      });
      return new TeamNode(resp.data);
    } catch (e) {
      await this.checkError(e);
    }

    return null;
  }

  // === Radars ===

  async getRadars(teamId: string): Promise<Array<RadarNode>> {
    const radars = [];
    const url = this.defaultUrlForAPI + '/radars' + (teamId ? '?teamId=' + teamId : '');
    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'radars',
        verb: 'GET',
        defaultKeyUrl: url,
      });
      for (const r of resp.data.radars) {
        const radar = new RadarNode(r);
        radars.push(radar);
      }
    } catch (e) {
      await this.checkError(e);
    }
    return radars;
  }

  async getRadar(id: string): Promise<RadarNode> {
    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'radars',
        verb: 'GET',
        relativePath: id,
        defaultKeyUrl: this.defaultUrlForAPI + '/radars'
      });
      return new RadarNode(resp.data);
    } catch (e) {
      await this.checkError(e);
    }

    return null;
  }

  async putRadar(radarNode: RadarNode, configurationAsJSON?: any): Promise<RadarNode> {
    const data = {
      name: radarNode.name,
    };

    if (configurationAsJSON) {
      data['configurationAsJSON'] = configurationAsJSON;
    }

    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'radars',
        relativePath: radarNode.id,
        verb: 'PUT',
        data,
        defaultKeyUrl: this.defaultUrlForAPI + '/radars/'
      });

      return new RadarNode(resp.data);
    } catch (e) {
      console.error('removeRadar error:', e);
    }
  }

  async getLonelyRadars(rains: RainNode[]): Promise<RadarNode[]> {
    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'radars',
        verb: 'GET',
        defaultKeyUrl: this.defaultUrlForAPI + '/radars'
      });
      const lonelyRadars: RadarNode[] = [];
      const radars = resp.data.radars;
      radars.forEach(radar => {
        let found = false;
        rains.forEach(rain => {
          const rdId = rain.getLinkId('radar', 0);
          if (rdId === radar.id) {
            found = true;
          }
        });
        if (!found) {
          lonelyRadars.push(radar);
        }
      });
      return lonelyRadars;
    } catch (e) {
      await this.checkError(e);
    }
    return [];
  }

  async getRainTimeframe(rainId: string, begin: Date, end: Date, forced = false): Promise<RainNode> {
    try {
      let queryPath = '' + rainId + '?format=timeframe';
      if (begin) {
        queryPath += '&begin=' + begin.toISOString();
      }
      if (end) {
        queryPath += '&end=' + end.toISOString();
      }
      if (forced) {
        queryPath += '&forced=true';
      }
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'rains',
        verb: 'GET',
        relativePath: queryPath,
        defaultKeyUrl: this.defaultUrlForAPI + '/rains'
      });
      const rainNode = new RainNode(resp.data.timeframe);
      rainNode.name += '.radar.timeframe';
      return rainNode;
    } catch (e) {
      await this.checkError(e);
    }
    return null;
  }

  // === Rains ===

  async getRains(teamId: string): Promise<Array<RainNode>> {
    const rains = [];
    const url = this.defaultUrlForAPI + '/rains' + (teamId ? '?teamId=' + teamId : '');
    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'rains',
        verb: 'GET',
        defaultKeyUrl: url,
      });
      for (const rain of resp.data.rains) {
        rains.push(new RainNode(rain));
      }
    } catch (e) {
      await this.checkError(e);
    }
    return rains;
  }

  async getRain(id: string): Promise<RainNode> {
    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'rains',
        relativePath: id,
        verb: 'GET',
        defaultKeyUrl: this.defaultUrlForAPI + '/rains/'
      });
      return new RainNode(resp.data);
    } catch (e) {
      await this.checkError(e);
    }
    return null;
  }

  // === Count

  async getCounts(rainId: string,
                  periodBegin?: Date,
                  periodEnd?: Date,
                  withQualities?: boolean): Promise<{
    radarMeasures: XYType[],
    gaugeMeasures: XYType[],
    computationsByTeam: XYType[],
    computationsInternal: XYType[],
  }> {
    try {

      let args = '';
      if (periodBegin && periodEnd) {
        args = '?periodBegin=' + periodBegin.toISOString() + '&periodEnd=' + periodEnd.toISOString();
      }
      if (withQualities) {
        args += '&withQualities=' + withQualities;
      }

      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'rains',
        relativePath: rainId + '/counts' + args,
        verb: 'GET',
        defaultKeyUrl: this.defaultUrlForAPI + '/rains/',
      });

      const counts = resp.data.counts;
      const radarMeasures: XYType[] = [],
        gaugeMeasures: XYType[] = [],
        computationsByTeam: XYType[] = [],
        computationsInternal: XYType[] = [];

      counts.forEach(c => {
        radarMeasures.push(new XYType(1, NaN, NaN, c._id));
        gaugeMeasures.push(new XYType(c.gaugeMeasures, NaN, NaN, c._id));
        computationsByTeam.push(new XYType(c.computationsByTeam, NaN, NaN, c._id));
        computationsInternal.push(new XYType(c.computationsInternal, NaN, NaN, c._id));
      });

      return {
        radarMeasures,
        gaugeMeasures,
        computationsByTeam,
        computationsInternal,
      };
    } catch (e) {
      await this.checkError(e);
    }
    return null;
  }

  // === Computing ===

  async launchRainComputation(rainId: string,
                              date: Date): Promise<RainComputation[]> {
    const response = await this.fidjAngularService.sendOnEndpoint({
      verb: 'POST',
      key: 'rains',
      relativePath: '' + rainId + '/computations',
      data: {
        date: date.toISOString(),
      },
      defaultKeyUrl: this.defaultUrlForAPI + '/rains'
    });

    return response.data.computations?.map(c => new RainComputation(c));
  }

  async getRainComputationCartesianMapById(rainId: string, rainComputationId: string): Promise<RainComputationMap> {
    const queryPath = '' + rainId + '/computations/' + rainComputationId + '?format=cartesian-map';
    const response = await this.fidjAngularService.sendOnEndpoint({
      key: 'rains',
      verb: 'GET',
      relativePath: queryPath,
      defaultKeyUrl: this.defaultUrlForAPI + '/rains'
    });
    if (!response.data['cartesian-map']) {
      return null;
    }

    const rainComputationMap = new RainComputationMap(response.data['cartesian-map']);
    rainComputationMap.name = rainId + '.rain.cartesian-map';
    return rainComputationMap;
  }

  async getRainCompares(rainId: string, rainComputationId: string): Promise<{ id: string, version: string }[]> {
    const queryPath = '' + rainId + '/computations/' + rainComputationId;
    const response = await this.fidjAngularService.sendOnEndpoint({
      key: 'rains',
      verb: 'GET',
      relativePath: queryPath,
      defaultKeyUrl: this.defaultUrlForAPI + '/rains',
      // timeout: 200000,
    });

    const rainComputation = new RainComputation(response.data);
    const links = rainComputation.getLinks();
    const rainComputationQualities = [];
    for (const link of links) {
      if (link.rel === 'rain-computation-quality') {
        const id = link.getId();
        const version = link.href.substring(link.href.indexOf('version/') + 8, link.href.indexOf('/' + id));
        rainComputationQualities.push({id, version});
      }
    }
    return rainComputationQualities;
  }

  async getRainCompareById(rainId: string,
                           rainComputationId: string,
                           rainComputationQualityId: string): Promise<RainComputationQuality> {
    const queryPath = '' + rainId + '/computations/' + rainComputationId + '/compares/' + rainComputationQualityId;
    const response = await this.fidjAngularService.sendOnEndpoint({
      key: 'rains',
      verb: 'GET',
      relativePath: queryPath,
      defaultKeyUrl: this.defaultUrlForAPI + '/rains',
    });

    const rainComputationQuality = new RainComputationQuality(response.data);
    rainComputationQuality.qualitySpeedMatrixContainer = SpeedMatrixContainer
      .CreateFromJson(rainComputationQuality.qualitySpeedMatrixContainer);
    return rainComputationQuality;
  }

  // === Gauges ===

  async getGauge(gaugeId: string): Promise<GaugeNode> {
    const resp = await this.fidjAngularService.sendOnEndpoint({
      key: 'gauges',
      verb: 'GET',
      relativePath: gaugeId,
      defaultKeyUrl: this.defaultUrlForAPI + '/gauges'
    });
    return new GaugeNode(resp.data);
  }

  async getGauges(rainId?: string): Promise<Array<GaugeNodeFilter>> {
    let queryPath = '';
    if (this.asTeamId) {
      queryPath += '?teamId=' + this.asTeamId;
    }
    if (rainId) {
      if (queryPath) {
        queryPath += '&';
      } else {
        queryPath += '?';
      }
      queryPath += 'rainId=' + rainId;
    }

    const gauges = [];
    try {
      const resp = await this.fidjAngularService.sendOnEndpoint({
        key: 'gauges',
        verb: 'GET',
        relativePath: queryPath,
        defaultKeyUrl: this.defaultUrlForAPI + '/gauges'
      });

      for (const gauge of resp.data.gauges) {
        gauges.push(new GaugeNodeFilter(gauge));
      }
    } catch (e) {
      await this.checkError(e);
    }
    return gauges;
  }

  async getGaugeMeasures(gaugeId: string, begin: Date, end: Date): Promise<GaugeMeasure[]> {
    const queryPath = '' + gaugeId + '/measures?begin=' + begin.toISOString() + '&end=' + end.toISOString();
    const resp = await this.fidjAngularService.sendOnEndpoint({
      key: 'gauges',
      verb: 'GET',
      relativePath: queryPath,
      defaultKeyUrl: this.defaultUrlForAPI + '/gauges'
    });

    const gaugeMeasures: GaugeMeasure[] = [];
    for (const gaugeMeasure of resp.data.gaugeMeasures) {
      gaugeMeasures.push(new GaugeMeasure(gaugeMeasure));
    }
    return gaugeMeasures;
  }

  // === Private ===

  protected setRoles(roles: Array<string>): void {
    this.roles = roles;
  }

}
