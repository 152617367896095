import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Cache {

  private readonly _cache: any;

  constructor() {
    this._cache = {};
  }

  public async getValue(key: string, asyncGetter): Promise<any> {
    if (!this._cache.hasOwnProperty(key)) {
      console.log('cache not done: ', key);
      this.putValue(key, await asyncGetter());
    } else {
      console.log('cache done: ', key);
    }
    return this._cache[key];
  }

  public putValue(key: string, value: any): void {
    this._cache[key] = value;

    const length = Object.getOwnPropertyNames(this._cache).length;
    if (length > 30) {
      console.warn('cache size exceed ? do a restart ?', length);
    }
  }

}
