import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {IconOptions} from 'leaflet';
import {CartesianMapValue, ElementsFactory, FrameContainer, MapElement, MapLatLng, TimeframeContainers} from 'raain-ui';

@Component({
  selector: 'raain-map',
  templateUrl: './raain-map.component.html',
  styleUrls: ['./raain-map.component.scss'],
})
export class RaainMapComponent implements AfterViewChecked, OnDestroy, OnChanges {

  @Input()
  public coordinates: MapLatLng;
  @Input()
  public markers: {
    gauges: MapLatLng[],
    selectedGauges: MapLatLng[],
    pixels: MapLatLng[],
    borders: MapLatLng[],
    gaugesInCompare: MapLatLng[],
    pixelsSolution: MapLatLng[],
  };
  @Input()
  public timeframeContainers: TimeframeContainers;
  @Input()
  public autoplay = false;
  @Input()
  public showMarkers = true;
  @Input()
  public currentHeight: number;
  public currentWidth: number;

  @Input()
  public timeframeDates: Date[] = [];
  @Input()
  public defaultDate: Date;

  @Input()
  public sumValues: any;
  @Input()
  public sumFn: any;
  public sumResult: string;

  @Output()
  public selectedMarker = new EventEmitter<MapLatLng>();

  @Output()
  public changedDate = new EventEmitter<Date>();

  public mcText;
  public nextDate: Date;
  public possibleNames: string[];
  public possibleDates: Date[];
  public dateToShow: Date;
  public dateToShowAsString: string;
  public timeframeNameToShow: string;
  protected currentFrameContainer: FrameContainer;

  @ViewChild('primaryMap')
  protected mapDivRef: ElementRef;

  private mapElement: MapElement;
  private onSizeChanged;
  private created;
  private iconOptions: {
    gauges: IconOptions,
    selectedGauges: IconOptions,
    pixels: IconOptions,
    borders: IconOptions,
    gaugesInCompare: IconOptions,
    pixelsSolution: IconOptions,
  };

  constructor(private ngZone: NgZone) {
    this.delete();

    // shadowUrl ?
    const iconSize = [40, 40]; // size of the icon
    const iconAnchor = [20, 20]; // point of the icon which will correspond to marker's location
    const pixelSize = [41, 41];
    const pixelAnchor = [20, 20];
    const popupAnchor = [0, -20]; // point from which the popup should open relative to the iconAnchor
    this.iconOptions = {
      gauges: {iconUrl: './assets/maps/marker-pluvio.png', iconSize, iconAnchor, popupAnchor},
      gaugesInCompare: {iconUrl: './assets/maps/marker-pluvio-diag.png', iconSize, iconAnchor, popupAnchor},
      selectedGauges: {iconUrl: './assets/maps/marker-pluvio-diag-select.png', iconSize, iconAnchor, popupAnchor},
      pixels: {iconUrl: './assets/maps/marker-rain.png', iconSize, iconAnchor, popupAnchor},
      pixelsSolution: {iconUrl: './assets/maps/marker-rain2.png', iconSize: pixelSize, iconAnchor: pixelAnchor, popupAnchor},
      borders: {iconUrl: './assets/maps/marker-shadow.png', iconSize, iconAnchor, popupAnchor},
    };
  }

  public ngAfterViewChecked(): void {
    this.create();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.change(changes);
  }

  public ngOnDestroy(): void {
    this.delete();
  }

  public showTimeframe(force = false) {

    // const {frameContainer, layer} = this.timeframeContainers.showTimeframe(this.timeframeNameToShow);
    // this.currentFrameContainer = frameContainer;

    if (!this.dateToShowAsString
      || (!force && new Date(this.dateToShowAsString).getTime() === this.dateToShow?.getTime())) {
      return;
    }

    this.dateToShow = new Date(this.dateToShowAsString);
    this.changedDate.emit(this.dateToShow);
  }

  public next() {
    if (!this.possibleDates || this.possibleDates.length === 0) {
      return;
    }

    const index = this.getDateToShowIndex();
    this.dateToShow = (index + 1) < this.possibleDates.length ? this.possibleDates[index + 1] : this.possibleDates[0];
    this.dateToShowAsString = this.dateToShow.toISOString();

    this.showTimeframe(true);
    if (this.autoplay) {
      window.setTimeout(() => this.next(), 2000);
    }
  }

  public previous() {
    this.stop();
    if (!this.possibleDates || this.possibleDates.length === 0) {
      return;
    }

    const index = this.getDateToShowIndex();
    this.dateToShow = index > 0 ? this.possibleDates[index - 1] : this.possibleDates[0];
    this.dateToShowAsString = this.dateToShow.toISOString();

    this.showTimeframe(true);
  }

  public play() {
    this.autoplay = true;
    this.next();
  }

  public stop() {
    this.autoplay = false;
  }

  public changeShowMarkers() {
    if (!this.mapElement?.markersLayer || !this.mapElement?.mapLeaflet) {
      return;
    }

    if (this.showMarkers) {
      this.mapElement.markersLayer.addToMap(this.mapElement.mapLeaflet);
    } else {
      this.mapElement.markersLayer.removeFromMap(this.mapElement.mapLeaflet);
    }
  }

  private create() {

    const created = this.created;
    if (created) {
      return;
    }
    this.created = true;
    const markers = [
      {iconsLatLng: this.markers.gauges, iconsOptions: this.iconOptions.gauges},
      {iconsLatLng: this.markers.selectedGauges, iconsOptions: this.iconOptions.selectedGauges},
      {iconsLatLng: this.markers.gaugesInCompare, iconsOptions: this.iconOptions.gaugesInCompare},
      {iconsLatLng: this.markers.pixels, iconsOptions: this.iconOptions.pixels},
      {iconsLatLng: this.markers.pixelsSolution, iconsOptions: this.iconOptions.pixelsSolution},
      // {iconsLatLng: this.markers.borders, iconsOptions: this.iconOptions.borders},
    ];

    const factory = new ElementsFactory(this.coordinates, true);
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {

        const createdOutside = this.created;
        if (!createdOutside) {
          return;
        }

        this.mapElement = factory.createMap(this.mapDivRef.nativeElement, {timeframeContainers: this.timeframeContainers, markers});

        this.mapElement.mapLeaflet?.on('mousemove', (evt) => this.onMapMouseMove(evt));
        this.mapElement.markersProduced?.forEach(m => m.on('click', evt => this.onMapClick(evt)));
        this.onSizeChanged = () => {
          this.mapElement.markersLayer?.setCurrentWidth(this.currentWidth);
          this.mapElement.markersLayer?.setCurrentHeight(this.currentHeight);
          this.mapElement.compositeLayer?.setCurrentWidth(this.currentWidth);
          this.mapElement.compositeLayer?.setCurrentHeight(this.currentHeight);
          this.mapElement.mapLeaflet?.invalidateSize({animate: true});
        }
        this.updateMapSize();
        // this.timeframeNameToShow = this.possibleNames.length > 0 ? this.possibleNames[0] : null;
        // this.toggleHideMarkers(!this.hideMarkers);
        this.changeNavigation(this.defaultDate);
      }, 100);
    });

  }

  private delete() {
    this.mapElement?.mapLeaflet?.remove();
    this.mapElement = undefined;
    this.mcText = '';
    this.nextDate = undefined;
    this.possibleNames = [];
    this.possibleDates = [];
    this.timeframeNameToShow = undefined;
    this.dateToShow = undefined;
    this.dateToShowAsString = undefined;
    this.onSizeChanged = () => {
    };
    this.currentFrameContainer = null;

    this.created = false;
  }

  private change(changes: SimpleChanges) {
    // console.log('map changes', changes);

    let possibleNewDefaultDate: Date;
    if (changes.defaultDate) {
      if (new Date(changes.defaultDate.currentValue).getTime() === new Date(changes.defaultDate.previousValue).getTime()) {
        return;
      }
      possibleNewDefaultDate = this.defaultDate;
    }

    if (changes.markers && this.mapElement?.markersLayer) {

      const markers = [
        {iconsLatLng: this.markers.gauges, iconOptions: this.iconOptions.gauges},
        {iconsLatLng: this.markers.selectedGauges, iconOptions: this.iconOptions.selectedGauges},
        {iconsLatLng: this.markers.gaugesInCompare, iconOptions: this.iconOptions.gaugesInCompare},
        {iconsLatLng: this.markers.pixels, iconOptions: this.iconOptions.pixels},
        {iconsLatLng: this.markers.pixelsSolution, iconOptions: this.iconOptions.pixelsSolution},
        //  {iconsLatLng: this.markers.borders, iconOptions: this.iconOptions.borders},
      ];
      const markersProduced = this.mapElement.markersLayer.render(markers).markers;
      if (markersProduced) {
        markersProduced.forEach(m => m.on('click', evt => this.onMapClick(evt)));
        if (this.showMarkers) {
          this.mapElement.markersLayer.removeFromMap(this.mapElement.mapLeaflet);
          this.mapElement.markersLayer.addToMap(this.mapElement.mapLeaflet);
        }
      }
    }

    if (changes.markers && this.markers.selectedGauges.length === 1) {
      const selectedGauge = this.markers.selectedGauges[0];
      this.mapElement.mapLeaflet.setView(selectedGauge, 10);
    }

    if (this.mapElement && changes.timeframeContainers) {
      this.mapElement.updateMapTimeframe(this.timeframeContainers);
      this.currentFrameContainer = this.timeframeContainers?.containers[0]?.timeframe[0];
    }

    if (this.mapElement?.compositeLayer && changes.sumValues) {
      const drawer = this.mapElement.compositeLayer.getFirstDrawer();
      this.sumResult = drawer?.getExecOfVisiblePoints(this.sumValues, this.sumFn);
    }

    this.changeNavigation(possibleNewDefaultDate);
  }

  private changeNavigation(defaultDate?: Date) {
    // update possible names
    if (this.timeframeContainers && this.timeframeContainers.containers.length) {
      this.possibleNames = [];
      this.timeframeContainers?.containers.forEach(c => {
        if (this.possibleNames.indexOf(c.name) < 0) {
          this.possibleNames.push(c.name);
        }
      });
      this.timeframeNameToShow = this.possibleNames.length > 0 ? this.possibleNames[0] : null;
    }

    // update possible dates
    this.possibleDates = this.timeframeDates;
    if (!this.possibleDates || this.possibleDates.length === 0) {
      return;
    }

    // update dateToShow
    if (defaultDate && this.possibleDates.filter(d => d.getTime() === defaultDate.getTime()).length === 1) {
      this.dateToShow = defaultDate;
    }
    if (!this.dateToShow && this.possibleDates.length > 0 &&
      this.possibleDates.filter(d => d.getTime() === this.dateToShow?.getTime()).length !== 1) {
      this.dateToShow = this.possibleDates[0];
    }
    this.dateToShowAsString = this.dateToShow.toISOString();
  }

  private getDateToShowIndex(): number {

    let index = 0;
    for (const date of this.possibleDates) {
      if (date.toISOString().substring(0, 19) === this.dateToShowAsString.substring(0, 19)) {
        break;
      }
      index++;
    }
    return index;
  }

  private onMapMouseMove(evt: { latlng: { lat: number, lng: number } }): void {

    const lat: string = evt.latlng.lat.toFixed(3);
    const lng: string = evt.latlng.lng.toFixed(3);

    let value = NaN;
    let valueLat = NaN, valueLng = NaN, valueLat2 = NaN, valueLng2 = NaN;
    if (this.currentFrameContainer) {
      const valueSelected = (this.currentFrameContainer.values as CartesianMapValue[])
        .filter((v: CartesianMapValue) => {
          return v.lat <= evt.latlng.lat && evt.latlng.lat < v.lat2 &&
            v.lng <= evt.latlng.lng && evt.latlng.lng < v.lng2;
        });

      if (valueSelected.length === 1) {
        value = Math.round(valueSelected[0].value * 1000) / 1000;
        valueLat = Math.round(valueSelected[0].lat * 1000) / 1000;
        valueLng = Math.round(valueSelected[0].lng * 1000) / 1000;
        valueLat2 = Math.round(valueSelected[0].lat2 * 1000) / 1000;
        valueLng2 = Math.round(valueSelected[0].lng2 * 1000) / 1000;
      } else {
        // console.warn('valueSelected issue ?', valueSelected);
      }
    }

    this.ngZone.run(() => {
      this.mcText = `Latitude: ${lat} &nbsp; &nbsp; Longitude: ${lng} &nbsp; &nbsp; Value: [${valueLat} ${valueLng} | ${valueLat2} ${valueLng2}] ${value}`;
    });
  }

  private onMapClick(evt: any): void {
    this.selectedMarker.emit(new MapLatLng(
      evt.latlng?.lat, evt.latlng?.lng, undefined,
      evt.id, evt.name));
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any): void {
    this.updateMapSize();
  }

  private updateMapSize(): void {

    const mapDivWidth = this.mapDivRef.nativeElement.parentNode?.parentNode['clientWidth'];
    const padding = 21;
    let currentWidth = mapDivWidth ||
      (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    const currentHeight = this.currentHeight ||
      (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    currentWidth -= padding;
    this.currentWidth = currentWidth;
    this.currentHeight = currentHeight;

    this.ngZone.run(() => {
      this.onSizeChanged();
    });
  }

}
