<ion-grid>

  <ion-row>
    <ion-col class="ion-text-left" size-sm="1">
      <ion-button (click)="focusReset()">
        View all dates
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-end">
    <ion-col class="ion-text-left" size-sm="1">
      <ion-button (click)="focusPrevious()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col id="col-container">
      <div [style.height.px]="currentHeight" [style.width.px]="currentWidth" id="canvas-container">
        <canvas #canvasElement></canvas>
      </div>
    </ion-col>
    <ion-col class="ion-text-left">
      <ion-button (click)="focusNext()">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
