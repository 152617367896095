<ion-content>

  <ion-card class="welcome-card">
    <ion-img src="/assets/login.png"></ion-img>

    <ion-card-header>
      <ion-card-title>Wrong url :(</ion-card-title>
      <ion-card-subtitle><a href="/">...back</a></ion-card-subtitle>
    </ion-card-header>

  </ion-card>
</ion-content>
