import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DateRange, DateStatusElement, DateStatusElementInput, ElementsFactory} from 'raain-ui';

@Component({
  selector: 'raain-date-focus',
  templateUrl: './raain-date-focus.component.html',
  styleUrls: ['./raain-date-focus.component.scss'],
})
export class RaainDateFocusComponent implements AfterViewChecked, OnDestroy, OnChanges {

  @Input()
  public points: {
    label: string,
    style: string,
    values: { date: Date, value: number }[],
  }[];

  @Input()
  public focusDate: Date;
  @Input()
  public focusRange: DateRange;

  @Input()
  public currentHeight: number;
  public currentWidth: number;

  @ViewChild('canvasElement')
  protected canvasElement: ElementRef;

  private dateStatusElement: DateStatusElement;
  private onSizeChanged;
  private created: boolean;

  constructor(private ngZone: NgZone) {
    this.focusDate = new Date()
    this.focusRange = DateRange.CENTURY;
    this.onSizeChanged = () => {
    };
  }

  public ngAfterViewChecked(): void {
    this.create();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.change(changes);
  }

  public ngOnDestroy(): void {
    this.delete();
  }

  focusPrevious() {
    this.dateStatusElement.focusPrevious();
  }

  focusReset() {
    this.dateStatusElement.focusReset();
  }

  focusNext() {
    this.dateStatusElement.focusNext();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any): void {
    this.updateMapSize();
  }

  private create() {
    if (this.created) {
      return;
    }
    this.created = true;

    // this.ngZone.runOutsideAngular(() => {
    //  setTimeout(() => {
    const factory = new ElementsFactory();
    try {
      this.dateStatusElement = factory.createDateStatus(this.canvasElement.nativeElement,
        new DateStatusElementInput(this.points, this.focusDate, this.focusRange));
    } catch (e) {
      console.warn('focus creation issue', e);
    }
    //  }, 50);
    // });

  }

  private async delete() {
    await this.dateStatusElement?.chart?.destroy();
    this.created = false;
  }

  private async change(changes: SimpleChanges) {
    await this.delete();
    this.create();
  }

  private updateMapSize(): void {

    const mapDivWidth = this.canvasElement.nativeElement.parentNode?.parentNode['clientWidth'];
    const padding = 20;
    let currentWidth = mapDivWidth ||
      (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    const currentHeight = this.currentHeight ||
      (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    currentWidth -= padding;
    this.currentWidth = currentWidth;
    this.currentHeight = currentHeight;

    this.ngZone.run(() => {
      this.onSizeChanged();
    });
  }
}
