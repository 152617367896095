import {CartesianMeasureValue, CartesianTools, ICartesianMeasureValue, RainNode} from 'raain-model';
import {CartesianMapValue} from 'raain-ui';
import {RadarService} from '../radar.service';
import {ProfileService} from '../profile.service';

export class ReportManager {

  public sumFn: any;
  public sumValues: any;
  protected cartesianTools: CartesianTools;

  constructor(
    protected rainNode: RainNode,
    protected radarService: RadarService,
    protected profileService: ProfileService) {
    this.cartesianTools = new CartesianTools();
    this.cartesianTools.buildLatLngEarthMap();
  }

  async setDate(dateShown: Date) {
    const {values, timeframesFromRadars} = await this.refreshTimeframesFromRadars(dateShown, dateShown);
    this.sumFn = (cartesianMapValues: CartesianMapValue[]) => {
      let sumResult = 0;
      if (cartesianMapValues?.length) {
        sumResult = cartesianMapValues.reduce((p, v) =>
          p + (v.value * 5 / 60), 0);
        sumResult = Math.round((sumResult / cartesianMapValues.length) * 10) / 10;
      }
      return 'w:' + sumResult;
    }
    this.sumValues = values;
  }

  async setPeriod(begin: Date, end: Date) {
    const {values, timeframesFromRadars} = await this.refreshTimeframesFromRadars(begin, end);
    this.sumFn = (cartesianMapValues: CartesianMapValue[]) => {
      let sumResult = 0;
      if (cartesianMapValues?.length) {
        sumResult = cartesianMapValues.reduce((p, v) =>
          p + (v.value * 5 / 60), 0);
        sumResult = Math.round((sumResult / cartesianMapValues.length) * 10) / 10; // timeframesFromRadars.length
      }
      return 'w:' + sumResult; // wt
    }
    this.sumValues = values;
  }

  protected async refreshTimeframesFromRadars(begin: Date, end: Date) {

    this.rainNode = await this.profileService.getRainTimeframe(this.rainNode.getId(), begin, end);

    const timeframesFromRadarsExtended = await this.radarService.getTimeline(this.rainNode);
    const timeframesFromRadars = timeframesFromRadarsExtended
      .filter((frameSet) => begin.getTime() <= frameSet.date.getTime() && frameSet.date.getTime() <= end.getTime())
      .sort((a, b) => a.date.getTime() - b.date.getTime());

    let values: CartesianMapValue[] = [];
    for (const frameSet of timeframesFromRadars) {
      values = values.concat(await this.getValues(frameSet));
    }

    return {values, timeframesFromRadars};
  }

  protected async getValues(target: {
    rainComputationId: string
  }): Promise<CartesianMapValue[]> {
    if (!target || !target.rainComputationId) {
      console.warn('Pb with target insights');
      return [];
    }

    let values: CartesianMapValue[] = [];
    try {
      const rainNodeCartesianMap = await this.profileService
        .getRainComputationCartesianMapById(this.rainNode.getId(), target.rainComputationId);

      if (rainNodeCartesianMap) {
        rainNodeCartesianMap.getMapData().forEach(m => {
          if (m.values) {
            (m.values as ICartesianMeasureValue[]).forEach(v => {
              const cartesianMeasureValue = CartesianMeasureValue.From(v);
              values = values.concat(CartesianMapValue.From(
                cartesianMeasureValue.getCartesianValues(),
                this.cartesianTools));
            });
          }
        });
      }
    } catch (e) {
      console.warn('Pb with computations for id:', target.rainComputationId, e);
    }

    return values;
  }

}
