import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ChartScaleColors, ConfigurationElement, ConfigurationElementInput, ElementsFactory, Tools} from 'raain-ui';
import {XYType} from '../xytype';

@Component({
  selector: 'raain-configuration',
  templateUrl: './raain-configuration.component.html',
  styleUrls: ['./raain-configuration.component.scss'],
})
export class RaainConfigurationComponent implements AfterViewChecked, OnDestroy, OnChanges {

  @Input()
  public points: XYType[];

  @Output()
  public changedPoints = new EventEmitter<XYType[]>();

  @Input()
  public currentHeight: number;

  @Input()
  public currentWidth: number;

  @Input()
  public minPoint: XYType = {x: 0, y: 0};

  @Input()
  public maxPoint: XYType = {x: 300, y: 100};

  @Input()
  public logStyle = false;

  @ViewChild('canvasElement')
  protected canvasElement: ElementRef;

  private configurationElement: ConfigurationElement;
  private onDrag;
  private onSizeChanged;
  private created;

  constructor(private ngZone: NgZone) {
    this.onSizeChanged = () => {
    };
    this.onDrag = (e: any) => {
      this.changedPoints.emit(this.points);
    }
  }

  public ngAfterViewChecked(): void {
    this.create();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.change(changes);
  }

  public ngOnDestroy(): void {
    this.delete();
  }

  async reset(count = 4) {
    const points = [];
    for (let c = 0; c < count; c++) {
      points.push(new XYType((c + 1) * 10, (c + 1) * 0.1));
    }
    this.points = points;
    await this.delete();
    this.create();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any): void {
    // this.updateMapSize();
  }

  private create() {
    if (this.created) {
      return;
    }
    this.created = true;

    let inputs = new ConfigurationElementInput(this.points,
      this.minPoint,
      this.maxPoint,
      false, [],
      this.onDrag);
    if (this.logStyle) {
      const confTransparency = 0.3;
      inputs = new ConfigurationElementInput(this.points,
        this.minPoint,
        this.maxPoint,
        true,
        [
          {
            color: Tools.getTransparency(ChartScaleColors['0'], confTransparency),
            yStart: Math.log10(0.1),
            yEnd: Math.log10(0.4)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['0.4'], confTransparency),
            yStart: Math.log10(0.4),
            yEnd: Math.log10(1)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['1'], confTransparency),
            yStart: Math.log10(1),
            yEnd: Math.log10(3)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['3'], confTransparency),
            yStart: Math.log10(3),
            yEnd: Math.log10(10)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['10'], confTransparency),
            yStart: Math.log10(10),
            yEnd: Math.log10(20)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['20'], confTransparency),
            yStart: Math.log10(20),
            yEnd: Math.log10(30)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['30'], confTransparency),
            yStart: Math.log10(30),
            yEnd: Math.log10(50)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['50'], confTransparency),
            yStart: Math.log10(50),
            yEnd: Math.log10(100)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['100'], confTransparency),
            yStart: Math.log10(100),
            yEnd: Math.log10(150)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['150'], confTransparency),
            yStart: Math.log10(150),
            yEnd: Math.log10(200)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['200'], confTransparency),
            yStart: Math.log10(200),
            yEnd: Math.log10(250)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['250'], confTransparency),
            yStart: Math.log10(250),
            yEnd: Math.log10(300)
          },
          {
            color: Tools.getTransparency(ChartScaleColors['300'], confTransparency),
            yStart: Math.log10(300),
            yEnd: Math.log10(500)
          },
        ],
        this.onDrag);
    }

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const factory = new ElementsFactory();
        this.configurationElement = factory.createConfiguration(this.canvasElement.nativeElement, inputs);
      }, 10);
    });

  }

  private async delete() {
    this.configurationElement?.chart?.destroy();
    this.created = false;
  }

  private async change(changes: SimpleChanges) {
    // console.log('changed', changes);
    await this.delete();
    this.create();
  }

  private updateMapSize(): void {

    const mapDivWidth = this.canvasElement.nativeElement.parentNode?.parentNode['clientWidth'];
    const padding = 20;
    let currentWidth = mapDivWidth ||
      (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    const currentHeight = this.currentHeight ||
      (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    currentWidth -= padding;
    this.currentWidth = currentWidth;
    this.currentHeight = currentHeight;

    this.ngZone.run(() => {
      this.onSizeChanged();
    });
  }
}
