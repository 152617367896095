<ion-grid>

  <ion-row class="ion-align-items-end">
    <ion-col id="map-buttons" size-xs="3">
      <ion-button (click)="previous()" color="medium" fill="outline" shape="round">
        <ion-icon name="play-back" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button (click)="stop()" [hidden]="!autoplay" color="medium" fill="outline"
                  shape="round">
        <ion-icon name="stop" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button (click)="next()" color="medium" fill="outline" shape="round">
        <ion-icon name="play-forward" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col class="ion-text-start" id="map-date" size-md="4" size-xs="5">
      <ion-select (ionDismiss)="showTimeframe()" [(ngModel)]="dateToShowAsString" interface="popover"
                  placeholder="Period" value="{{dateToShowAsString}}">
        <ion-select-option *ngFor="let dateToSelect of possibleDates" value="{{dateToSelect.toISOString()}}">
          {{ dateToSelect | date:'yyyy-MM-dd HH:mm' }}
        </ion-select-option>
      </ion-select>
    </ion-col>
    <ion-col class="ion-hide-md-down" size-xs="2">
      <ion-button color="medium" fill="clear">
        Gauges
        <ion-toggle (ionChange)="changeShowMarkers()" [(ngModel)]="showMarkers" [checked]="showMarkers"
                    color="medium"></ion-toggle>
      </ion-button>
    </ion-col>
    <ion-col class="ion-hide-md-down" size-xs="2">
      <ion-range (ionChange)="changeAlpha()" [(ngModel)]="alpha" [max]="1" [min]="0.2" [snaps]="true" [step]="0.2"
                 [ticks]="true"
                 color="medium"></ion-range>
    </ion-col>

    <!-- <ion-col class="ion-hide-md-down" size-xs="2">
       <ion-button color="medium" fill="clear">
         Cumulative
         <ion-toggle (ionChange)="changeCumulative()" [(ngModel)]="showCumulative" [checked]="showCumulative"
                     color="medium"></ion-toggle>
       </ion-button>
     </ion-col>-->
  </ion-row>

  <ion-row>
    <ion-col size-lg="10" size-xs="12">
      <div [style.height.px]="currentHeight" id="map-container">
        <div #primaryMap id="map-div"></div>
      </div>
    </ion-col>
    <ion-col class="ion-hide-xl-down" size-lg="2">
      <div [style.height.px]="currentHeight" [style.width.px]="100">
        <canvas #canvasScale></canvas>
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="ion-hide-sm-down" size-sm="7">
      <span [innerHTML]="mcText"></span>
    </ion-col>
    <ion-col size-sm="2" size-xs="12">
      <ion-button disabled="true" fill="clear">{{ sumResult }}</ion-button>
    </ion-col>
    <ion-col class="ion-hide-sm-down" size-sm="3">
      <ion-button disabled="true" fill="clear"><small>{{ dateToShow?.toISOString() }}</small></ion-button>
    </ion-col>
  </ion-row>

</ion-grid>
