import {GaugeNode, LatLng} from 'raain-model';

export class GaugeNodeFilter extends GaugeNode {

  public isIn = true;
  public distance = 0;

  constructor(json: any) {
    super(json);
  }

  approxDistanceFrom(center: LatLng): number {
    this.distance = Math.round((Math.pow(this.latitude - center.lat, 2) + Math.pow(this.longitude - center.lng, 2)) * 100 * 100) / 100;
    return this.distance;
  }

  add() {
    this.isIn = true;
  }

  remove() {
    this.isIn = false;
  }
}
