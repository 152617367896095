import {version} from './version';
import 'zone.js/dist/zone-error';

const somethingToAdd = '_sandbox';
export const environment = {
  production: false,
  description: somethingToAdd,
  fidjKey: 'fidj-sandbox-0123fe7ed0000002',
  serverUrl: 'https://api.sandbox.radartorain.com/v2',
  version: version + somethingToAdd
};
