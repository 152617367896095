export class FrameSet {
  constructor(public date: Date,
              public radarMeasureIds: string[] = [],
              public rainComputationId: string = null,
              public rainComputationVersion: string = null,
              public rainComputationQualities: {
                version: string,
                id: string
              }[] = []) {
  }
}
