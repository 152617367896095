import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {IconOptions} from 'leaflet';
import {
  CartesianMapValue,
  ChartScaleColors,
  ElementsFactory,
  FrameContainer,
  MapElement,
  MapLatLng,
  ScaleElement,
  ScaleElementInput,
  TimeframeContainer,
  TimeframeContainers
} from 'raain-ui';
import {Storage} from '../storage.service';

@Component({
  selector: 'raain-map',
  templateUrl: './raain-map.component.html',
  styleUrls: ['./raain-map.component.scss'],
})
export class RaainMapComponent implements AfterViewChecked, OnDestroy, OnChanges {

  @Input()
  public coordinates: MapLatLng;
  @Input()
  public markers: {
    gauges: MapLatLng[],
    selectedGauges: MapLatLng[],
    pixels: MapLatLng[],
    borders: MapLatLng[],
    gaugesInCompare: MapLatLng[],
    pixelsSolution: MapLatLng[],
  };
  @Input()
  public timeframeContainers: TimeframeContainers;
  @Input()
  public autoplay = false;
  @Input()
  public showMarkers = true;
  @Input()
  public showCumulative = false;
  @Input()
  public currentHeight: number;
  public currentWidth: number;

  @Input()
  public timeframeDates: Date[] = [];
  @Input()
  public defaultDate: Date;

  @Input()
  public sumValues: any;
  @Input()
  public sumFn: any;
  public sumResult: string;


  @Output()
  public selectedMarker = new EventEmitter<MapLatLng>();

  @Output()
  public changedDate = new EventEmitter<Date>();

  public alpha: number;
  public mcText;
  public nextDate: Date;
  public possibleNames: string[];
  public possibleDates: Date[];
  public dateToShow: Date;
  public dateToShowAsString: string;
  public timeframeNameToShow: string;
  protected currentFrameContainer: FrameContainer;
  protected cumulativeTimeframeContainers: TimeframeContainers;

  @ViewChild('primaryMap')
  protected mapDivRef: ElementRef;

  @ViewChild('canvasScale')
  protected scaleCanvasRef: ElementRef;

  private mapElement: MapElement;
  private scaleElement: ScaleElement;

  private onSizeChanged;
  private created;
  private iconOptions: {
    gauges: IconOptions,
    selectedGauges: IconOptions,
    pixels: IconOptions,
    borders: IconOptions,
    gaugesInCompare: IconOptions,
    pixelsSolution: IconOptions,
  };

  constructor(private ngZone: NgZone,
              private storage: Storage,) {
    this.delete();

    const iconSize = [40, 40]; // size of the icon
    const iconAnchor = [20, 20]; // point of the icon which will correspond to marker's location
    const pixelSize = [41, 41];
    const pixelAnchor = [20, 20];
    const popupAnchor = [0, -20]; // point from which the popup should open relative to the iconAnchor
    this.iconOptions = {
      gauges: {iconUrl: './assets/maps/marker-pluvio.png', iconSize, iconAnchor, popupAnchor},
      gaugesInCompare: {iconUrl: './assets/maps/marker-pluvio-diag.png', iconSize, iconAnchor, popupAnchor},
      selectedGauges: {iconUrl: './assets/maps/marker-pluvio-diag-select.png', iconSize, iconAnchor, popupAnchor},
      pixels: {iconUrl: './assets/maps/marker-rain.png', iconSize, iconAnchor, popupAnchor},
      pixelsSolution: {iconUrl: './assets/maps/marker-rain2.png', iconSize: pixelSize, iconAnchor: pixelAnchor, popupAnchor},
      borders: {iconUrl: './assets/maps/marker-shadow.png', iconSize, iconAnchor, popupAnchor},
    };
  }

  public ngAfterViewChecked(): void {
    this.create();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.change(changes);
  }

  public ngOnDestroy(): void {
    this.delete();
  }

  public showTimeframe(force = false) {
    if (!this.dateToShowAsString
      || (!force && new Date(this.dateToShowAsString).getTime() === this.dateToShow?.getTime())) {
      return;
    }

    this.dateToShow = new Date(this.dateToShowAsString);
    this.changedDate.emit(this.dateToShow);
  }

  public next() {
    if (!this.possibleDates || this.possibleDates.length === 0) {
      return;
    }

    const index = this.getDateToShowIndex();
    this.dateToShow = (index + 1) < this.possibleDates.length ? this.possibleDates[index + 1] : this.possibleDates[0];
    this.dateToShowAsString = this.dateToShow.toISOString();

    this.showTimeframe(true);
    if (this.autoplay) {
      window.setTimeout(() => this.next(), 2000);
    }
  }

  public previous() {
    this.stop();
    if (!this.possibleDates || this.possibleDates.length === 0) {
      return;
    }

    const index = this.getDateToShowIndex();
    this.dateToShow = index > 0 ? this.possibleDates[index - 1] : this.possibleDates[0];
    this.dateToShowAsString = this.dateToShow.toISOString();

    this.showTimeframe(true);
  }

  public play() {
    this.autoplay = true;
    this.next();
  }

  public stop() {
    this.autoplay = false;
  }

  public changeShowMarkers() {
    if (!this.mapElement?.markersLayer || !this.mapElement?.mapLeaflet) {
      return;
    }

    if (this.showMarkers) {
      this.mapElement.markersLayer.addToMap(this.mapElement.mapLeaflet);
    } else {
      this.mapElement.markersLayer.removeFromMap(this.mapElement.mapLeaflet);
    }
  }

  public changeCumulative() {
    if (this.showCumulative) {
      const factory = new ElementsFactory(this.coordinates, true);
      this.createScale(factory, 'mm');
      this.cumulativeTimeframeContainers = this.timeframeContainers;
    } else {
      const factory = new ElementsFactory(this.coordinates, true);
      this.createScale(factory, 'mm/h');
    }

    this.updateTimeframe();
  }

  public changeAlpha() {
    console.log('changeAlpha', this.alpha);
    this.storage.set('map-alpha', this.alpha);
    if (!this.mapElement || !this.mapElement.mapLeaflet) {
      return;
    }

    this.mapElement.alpha = 0.3 + this.alpha * 0.7;
    this.mapElement.mapLeaflet.eachLayer((layer: any) => {
      if (layer._container?.style) {
        layer._container.style.filter = `brightness(${(10 - this.alpha * 10) * 10}%)`; // invert(1) ?
      }
    });
  }

  private create() {

    const created = this.created;
    if (created) {
      return;
    }
    this.created = true;
    const markers = [
      {iconsLatLng: this.markers.gauges, iconsOptions: this.iconOptions.gauges},
      {iconsLatLng: this.markers.selectedGauges, iconsOptions: this.iconOptions.selectedGauges},
      {iconsLatLng: this.markers.gaugesInCompare, iconsOptions: this.iconOptions.gaugesInCompare},
      {iconsLatLng: this.markers.pixels, iconsOptions: this.iconOptions.pixels},
      {iconsLatLng: this.markers.pixelsSolution, iconsOptions: this.iconOptions.pixelsSolution},
      // {iconsLatLng: this.markers.borders, iconsOptions: this.iconOptions.borders},
    ];

    const factory = new ElementsFactory(this.coordinates, true);
    this.createScale(factory, 'mm/h');

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {

        const createdOutside = this.created;
        if (!createdOutside) {
          return;
        }

        this.mapElement = factory.createMap(this.mapDivRef.nativeElement,
          {timeframeContainers: this.timeframeContainers, markers});

        this.changeAlpha();

        this.mapElement.mapLeaflet?.on('mousemove', (evt) => this.onMapMouseMove(evt));
        this.mapElement.markersProduced?.forEach(m => m.on('click', evt => this.onMapClick(evt)));
        this.onSizeChanged = () => {
          this.mapElement.markersLayer?.setCurrentWidth(this.currentWidth);
          this.mapElement.markersLayer?.setCurrentHeight(this.currentHeight);
          this.mapElement.compositeLayer?.setCurrentWidth(this.currentWidth);
          this.mapElement.compositeLayer?.setCurrentHeight(this.currentHeight);
          this.mapElement.mapLeaflet?.invalidateSize({animate: true});
        }
        this.updateMapSize();
        this.changeNavigation(this.defaultDate);
      }, 100);
    });

  }

  private delete() {
    this.mapElement?.mapLeaflet?.remove();
    this.mapElement = undefined;
    this.mcText = '';
    this.nextDate = undefined;
    this.possibleNames = [];
    this.possibleDates = [];
    this.timeframeNameToShow = undefined;
    this.dateToShow = undefined;
    this.dateToShowAsString = undefined;
    this.onSizeChanged = () => {
    };
    this.currentFrameContainer = null;

    this.created = false;
    this.alpha = this.storage.get('map-alpha', 1);
  }

  private change(changes: SimpleChanges) {
    let possibleNewDefaultDate: Date;

    if (changes.defaultDate) {
      if (new Date(changes.defaultDate.currentValue).getTime() === new Date(changes.defaultDate.previousValue).getTime()) {
        return;
      }
      possibleNewDefaultDate = this.defaultDate;
    }

    if (changes.markers && this.mapElement?.markersLayer) {

      const markers = [
        {iconsLatLng: this.markers.gauges, iconOptions: this.iconOptions.gauges},
        {iconsLatLng: this.markers.selectedGauges, iconOptions: this.iconOptions.selectedGauges},
        {iconsLatLng: this.markers.gaugesInCompare, iconOptions: this.iconOptions.gaugesInCompare},
        {iconsLatLng: this.markers.pixels, iconOptions: this.iconOptions.pixels},
        {iconsLatLng: this.markers.pixelsSolution, iconOptions: this.iconOptions.pixelsSolution},
        //  {iconsLatLng: this.markers.borders, iconOptions: this.iconOptions.borders},
      ];
      const markersProduced = this.mapElement.markersLayer.render(markers).markers;
      if (markersProduced) {
        markersProduced.forEach(m => m.on('click', evt => this.onMapClick(evt)));
        if (this.showMarkers) {
          this.mapElement.markersLayer.removeFromMap(this.mapElement.mapLeaflet);
          this.mapElement.markersLayer.addToMap(this.mapElement.mapLeaflet);
        }
      }
    }

    if (changes.markers && this.markers.selectedGauges.length === 1) {
      const selectedGauge = this.markers.selectedGauges[0];
      this.mapElement.mapLeaflet.setView(selectedGauge, 10);
    }

    if (this.mapElement && changes.timeframeContainers) {
      this.updateTimeframe();
    }

    if (this.mapElement?.compositeLayer && changes.sumValues) {
      const drawer = this.mapElement.compositeLayer.getFirstDrawer();
      this.sumResult = drawer?.getExecOfWindowPoints(this.sumValues, this.sumFn);
    }

    this.changeNavigation(possibleNewDefaultDate);
  }

  private changeNavigation(defaultDate?: Date) {
    // update possible names
    if (this.timeframeContainers && this.timeframeContainers.containers.length) {
      this.possibleNames = [];
      this.timeframeContainers?.containers.forEach(c => {
        if (this.possibleNames.indexOf(c.name) < 0) {
          this.possibleNames.push(c.name);
        }
      });
      this.timeframeNameToShow = this.possibleNames.length > 0 ? this.possibleNames[0] : null;
    }

    // update possible dates
    this.possibleDates = this.timeframeDates;
    if (!this.possibleDates || this.possibleDates.length === 0) {
      return;
    }

    // update dateToShow
    if (defaultDate && this.possibleDates.filter(d => d.getTime() === defaultDate.getTime()).length === 1) {
      this.dateToShow = defaultDate;
    }
    if (!this.dateToShow && this.possibleDates.length > 0 &&
      this.possibleDates.filter(d => d.getTime() === this.dateToShow?.getTime()).length !== 1) {
      this.dateToShow = this.possibleDates[0];
    }
    this.dateToShowAsString = this.dateToShow.toISOString();
  }

  private getDateToShowIndex(): number {

    let index = 0;
    for (const date of this.possibleDates) {
      if (date.toISOString().substring(0, 19) === this.dateToShowAsString.substring(0, 19)) {
        break;
      }
      index++;
    }
    return index;
  }

  private onMapMouseMove(evt: { latlng: { lat: number, lng: number } }): void {

    const lat: string = evt.latlng.lat.toFixed(3);
    const lng: string = evt.latlng.lng.toFixed(3);

    let value = NaN;
    let valueLat = NaN, valueLng = NaN, valueLat2 = NaN, valueLng2 = NaN;
    if (this.currentFrameContainer) {
      const valueSelected = (this.currentFrameContainer.values as CartesianMapValue[])
        .filter((v: CartesianMapValue) => {
          return v.lat <= evt.latlng.lat && evt.latlng.lat < v.lat2 &&
            v.lng <= evt.latlng.lng && evt.latlng.lng < v.lng2;
        });

      if (valueSelected.length === 1) {
        value = Math.round(valueSelected[0].value * 1000) / 1000;
        valueLat = Math.round(valueSelected[0].lat * 1000) / 1000;
        valueLng = Math.round(valueSelected[0].lng * 1000) / 1000;
        valueLat2 = Math.round(valueSelected[0].lat2 * 1000) / 1000;
        valueLng2 = Math.round(valueSelected[0].lng2 * 1000) / 1000;
      } else {
        // console.warn('valueSelected issue ?', valueSelected);
      }
    }

    this.ngZone.run(() => {
      // this.mcText = `Latitude: ${lat} &nbsp; &nbsp; Longitude: ${lng} &nbsp; &nbsp;
      //    Value: [${valueLat} ${valueLng} | ${valueLat2} ${valueLng2}] ${value}`;
      this.mcText = `Latitude: ${lat} &nbsp; &nbsp; Longitude: ${lng} &nbsp; &nbsp; Value: ${value}`;
    });
  }

  private onMapClick(evt: any): void {
    for (const gauge of this.markers.gauges) {
      if (gauge.id === evt?.target?.options?.alt) {
        this.markers.selectedGauges = [gauge];
        break;
      }
    }

    this.selectedMarker.emit(new MapLatLng(
      evt.latlng?.lat, evt.latlng?.lng, undefined,
      evt.id, evt.name));

    this.change({markers: ['changed']} as any);
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any): void {
    this.updateMapSize();
  }

  private updateMapSize(): void {

    const mapDivWidth = this.mapDivRef.nativeElement.parentNode?.parentNode['clientWidth'];
    const padding = 21;
    let currentWidth = mapDivWidth ||
      (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    const currentHeight = this.currentHeight ||
      (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    currentWidth -= padding;
    this.currentWidth = currentWidth;
    this.currentHeight = currentHeight;

    this.ngZone.run(() => {
      this.onSizeChanged();
    });
  }

  private updateTimeframe() {

    if (!this.showCumulative) {
      this.mapElement.updateMapTimeframe(this.timeframeContainers);
      this.currentFrameContainer = this.timeframeContainers?.containers[0]?.timeframe[0];

    } else if (this.timeframeContainers.containers[0]?.timeframe) {

      for (const container of this.timeframeContainers.containers[0].timeframe) {
        const alreadyExist = this.cumulativeTimeframeContainers.containers[0].timeframe
          .filter(c => new Date(c.date).getTime() === new Date(container.date).getTime());
        if (alreadyExist.length < 1) {
          this.cumulativeTimeframeContainers.containers[0].timeframe.push(container);
        }
      }
      console.log(new Date().toISOString(), 'cartesianMapValues: computing...');
      const cartesianMapValues = this.cumulativeTimeframeContainers.getCumulativeCartesianValues();
      console.log(new Date().toISOString(), 'cartesianMapValues:', cartesianMapValues.length);
      this.currentFrameContainer = new FrameContainer(this.defaultDate, cartesianMapValues, false, true);

      this.mapElement.updateMapTimeframe(
        new TimeframeContainers([
          new TimeframeContainer(this.defaultDate.toISOString(),
            [this.currentFrameContainer],
            'cumulative')
        ]));

    }

    this.changeAlpha();
  }

  private createScale(factory: ElementsFactory, label: string) {
    this.deleteScale();

    const entries = Object.entries(ChartScaleColors);
    entries.sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]));
    const sortedMap = new Map(entries);
    const sortedArray = [...sortedMap.entries()];
    const scaleColors = sortedArray.map(entry => {
      return {color: entry[1]}
    });
    const scaleLabels = sortedArray.map(entry => entry[0]);
    this.scaleElement = factory.createScale(this.scaleCanvasRef.nativeElement,
      new ScaleElementInput(scaleColors, scaleLabels, label));
  }

  private deleteScale() {
    if (this.scaleElement) {
      this.scaleElement?.chart?.destroy();
    }
  }
}
